import WhyNLAImg1 from "../../../images/bg/why-5stars-service.png";
import WhyNLAImg2 from "../../../images/bg/why-expertise.png";
import WhyNLAImg3 from "../../../images/bg/why-competitive-price.png";
import WhyNLAImg4 from "../../../images/bg/why-flexibility.png";
import WhyNLAImg5 from "../../../images/bg/why-technology.png";
import WhyNLAImg6 from "../../../images/bg/why-service-through.png";
import WhyNLAImg7 from "../../../images/bg/why-market-leading.png";
import WhyNLAImg8 from "../../../images/bg/why-transparency.png";
import WhyNLAImg9 from "../../../images/bg/why-service-dealer.png";

export const ContentItems = [
  {
    selectorText: '5-Star customer service',
    title: '5-Star customer service',
    content: `At Novated Lease Australia, our exceptional customer service is evident in our <span style='font-weight:600;color:#6629D7'>5-star 
    ratings: with 700+ reviews on TrustPilot and 80+ on Google</span>. This reflects our 
    commitment to excellence and the trust our clients place in us, affirming our 
    leadership in customer satisfaction.`,
    image: WhyNLAImg1,
  },
  {
    selectorText: 'Expertise',
    title: 'Expertise',
    content: `At Novated Lease Australia, our team's <span style='font-weight:600;color:#6629D7'>300+ years of collective experience</span> make us 
    novated leasing experts. We offer <span style='font-weight:600;color:#6629D7'>straightforward, expert advice</span>, ensuring 
    every leasing aspect is clear, simple and meets your exact needs. `,
    image: WhyNLAImg2,
  },
  {
    selectorText: 'Competitive pricing',
    title: 'Competitive pricing',
    content: `By partnering with various financiers, we deliver <span style='font-weight:600;color:#6629D7'>tailored, 
    cost-effective leasing solutions</span>. Our easy online application demands minimal 
    documentation, ensuring a smooth journey to your novated lease. This reflects 
    our dedication to <span style='font-weight:600;color:#6629D7'>saving you time and money while providing a hassle-free experience</span>.`,
    image: WhyNLAImg3,
  },
  {
    selectorText: 'Flexibility',
    title: 'Flexibility',
    content: `Novated Lease Australia delivers exceptional flexibility, letting you <span style='font-weight:600;color:#6629D7'>choose your 
    insurance and service providers</span>. Our fleet card, accepted at most service 
    stations, <span style='font-weight:600;color:#6629D7'>simplifies expenses and budget adjustments</span>. Plus, we <span style='font-weight:600;color:#6629D7'>streamline 
    your old vehicle's trade-in</span>, ensuring an easy transition to your new lease.`,
    image: WhyNLAImg4,
  },
  {
    selectorText: 'Technology',
    title: 'Technology',
    content: `Novated Lease Australia uses <span style='font-weight:600;color:#6629D7'>technology to enhance your leasing journey</span>.  
    Our <span style='font-weight:600;color:#6629D7'>online application and electronic signing</span> quicken approvals and paperwork. 
    Plus, with <span style='font-weight:600;color:#6629D7'>online support and chat</span>, help is always within reach. 
    It all equals a <span style='font-weight:600;color:#6629D7'>smooth, efficient process from start to finish</span>.`,
    image: WhyNLAImg5,
  },
  {
    selectorText: 'Service through the entire lease',
    title: 'Service through the entire lease',
    content: `Novated Lease Australia ensures top service throughout your lease. Our <span style='font-weight:600;color:#6629D7'>Driver App offers 
    easy lease management</span>, while our customer service team provides fast support, 
    <span style='font-weight:600;color:#6629D7'>answering calls in 30 seconds and emails in under 2 hours</span>. Your personal lease 
    consultant is always ready to assist, ensuring a smooth, hassle-free experience.`,
    image: WhyNLAImg6,
  },
  {
    selectorText: 'Market leading turnaround times',
    title: 'Market leading turnaround times',
    content: `Novated Lease Australia is committed to fast service, ensuring quick access to your new 
    car with <span style='font-weight:600;color:#6629D7'>1 business day finance approvals and 4-hour settlements</span>. Receive quotes 
    promptly, often during your consultation, with <span style='font-weight:600;color:#6629D7'>all inquiries addressed within 24 hours</span>. 
    Our aim is to streamline your leasing process, getting you into your car swiftly and smoothly.`,
    image: WhyNLAImg7,
  },
  {
    selectorText: 'Transparency',
    title: 'Transparency',
    content: `Novated Lease Australia values transparency, providing <span style='font-weight:600;color:#6629D7'>clear, no-surprise quotations and 
    straightforward guidance</span>. Our consultants are ready to clarify any aspects of your lease, 
    ensuring you're well-informed and confident in your decisions.`,
    image: WhyNLAImg8,
  },
  {
    selectorText: 'Dealer & Manufacturer relationships',
    title: 'Dealer & Manufacturer relationships',
    content: `Novated Lease Australia uses our dealer and manufacturer connections to offer <span style='font-weight:600;color:#6629D7'>exclusive 
    benefits like access to private stock, discounts on popular models, and deals 
    on accessories</span>. Our Procurement team handles car sourcing to save you time and 
    effort, and we provide fleet discounts on many brands for a cost-effective 
    leasing experience tailored to you.`,
    image: WhyNLAImg9,
  },
];
