import styled from "styled-components";
import screenSize from '../../../styles/mediaQueries';

export const ScrollToTop = styled.div`
  height: 4.1em;
  width: 4.1em;
  background: white;
  border-radius: 50%;
  position: fixed;
  bottom: 80px;
  right: 20px;
  opacity: ${({ hidden }) => (hidden ? "0" : "1")};
  box-shadow: 11px 11px 20px 0 rgb(19 15 73 / 8%);
  transition: opacity 0.4s;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #e6e6e6;
    cursor: pointer;
  }

  ${screenSize.minLargeDesktop`
      height: 3.5em;
      width: 3.5em;
      bottom: 80px;
      right: 18px;
  `}
`;
