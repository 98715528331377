import React, { forwardRef } from "react";
import * as Styled from "./WhyNovatedLeaseAustralia.styled";
import AccordionContent from './AccordionContent';
import AccordionContentMobile from './AccordionContentMobile';

const WhyNovatedLeaseAustralia = forwardRef(({ isTablet, ...props }, ref) => {
  return (
    <Styled.Background>
      <Styled.MaxWidth ref={ref} id="whyNovatedLease">
        <Styled.BlockWrapper>
          <Styled.TitleWrapper>
            <Styled.Title>Why Novated Lease Australia</Styled.Title>
            <Styled.SubTitle>
              Novated Lease Australia will help you on every step of your journey on getting your new car
            </Styled.SubTitle>
          </Styled.TitleWrapper>
          {isTablet ? <AccordionContentMobile /> : <AccordionContent />}
        </Styled.BlockWrapper>
      </Styled.MaxWidth>
    </Styled.Background>
  );
});

export default WhyNovatedLeaseAustralia;
