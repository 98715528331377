import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root' : {
    fontFamily: 'Calibre, Helvetica Neue, Helvetica, sans-serif;',
    color: '#6629d6',
    padding: '3px 10px 6px 10px',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    marginLeft: '-5px',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    '&.Mui-error': {
      backgroundColor: '#fdeded',
    },
  },
  '& .MuiInputBase-input .MuiOutlinedInput-input': {
    backgroundColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E2E2E2',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#6629d6',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#6E42F7',
  },
  '& .MuiInputLabel-root.Mui-focused' : {
    color: '#6629d6',
    padding: '3px 10px 6px 10px',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    marginLeft: '-5px',
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#d32f2f',
  },
});

export const CustomFormControlLabel = styled(FormControlLabel)`
  && {
    display: flex;
    align-items: flex-start;
    .MuiTypography-root {
      font-size: 14px;
      color: #444248;
      fontFamily: 'Calibre, Helvetica Neue, Helvetica, sans-serif;',
    }
  }
`;

export const CustomCheckboxLabel = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#6629d6',
  },
  '&:hover': {
    backgroundColor: 'rgb(102 42 215 / 15%)',
  },
});