import React from "react";
import * as Styled from "./VehicleComparison.styled";
import Vehicle from "./Vehicle";

function VehicleComparison({isMobile, isTablet}) {
  return (
    <Styled.ComparisonBackground>
      <Styled.MaxWidth>
        <Styled.YellowTopArtfacts />
        <Styled.TitleWrapper>
          <Styled.Title>Petrol vs hybrid vs EV comparison</Styled.Title>
          <Styled.ContentText>
            <Styled.ContentTextTitle>Compare some of the most popular options in the petrol, hybrid and electric ranges</Styled.ContentTextTitle>
            <Styled.ContentTextBody>
              Find the perfect match for your needs, ensuring a vehicle that not
              only fits your lifestyle but also maximises your savings.
            </Styled.ContentTextBody>
          </Styled.ContentText>
        </Styled.TitleWrapper>
        <Vehicle isMobile={isMobile}  isTablet={isTablet} />
        <Styled.Disclaimer>
          * Based a on a driver in NSW for a 5 year lease term, with a $100,000 salary, and 15,000km driven annually.
        </Styled.Disclaimer>
      </Styled.MaxWidth>
    </Styled.ComparisonBackground>
  );
}

export default VehicleComparison;
