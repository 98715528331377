import React from "react";
import * as Styled from "./PromoCarousel.styled";

function SlideImage({ slideItem }) {
  return (
    <picture>
      <source media="(max-width: 799px)" srcSet={`${slideItem.imageMobile}`} />
      <source media="(min-width: 800px)" srcSet={`${slideItem.image}`} />
      <Styled.VehicleFullImg src={slideItem.image} alt={slideItem.vehicleTitle} />
    </picture>
  );
}

export default SlideImage;
