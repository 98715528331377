import styled, {css} from "styled-components";
import screenSize from "../../../styles/mediaQueries";

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 90px 40px;

  ${screenSize.minDesktop`
    display: grid;
    grid-gap: 40px;
    align-items: top;
    grid-template-columns: 30% 70%;
    margin: 90px 40px;
  `}
`;

export const Title = styled.h1`
  color: #6629D7;
  font-size: 2.7em;
  margin: 0;
`;

export const Titleh6 = styled.h6`
  color: #6629D7;
  font-size: 1.8em;
  margin: 0;
`;

export const Content = styled.p`
  font-size: 20px;
  padding: 20px 0;

  ${screenSize.minLargeDesktop`
    font-size: 1.2em;
  `}
  
`;

export const Divider = styled.div`
  border-top: 2px solid #ECECED;
  ${props => props.$css && css`${props.$css};`}
`;

export const DividerLast = styled.div`
  display: none;
  ${screenSize.minDesktop`
    display: block;
  `}
`;

export const AccordionWrapper = styled.div`
  margin: 0;

  ${screenSize.minDesktop`
    margin: 0 40px;
  `}
`;

export const VideoWrapper = styled.div`
  width: 100%;
  margin: auto;
  ${screenSize.minDesktop`
    max-width: 340px;
    margin: 0;
  `}
`;