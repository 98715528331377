import styled from 'styled-components';
import screenSize from '../../../styles/mediaQueries';

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px; 
 
  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const Top10LeasedWrapper = styled.div`
  margin: 60px 0 80px 0;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;

  ${screenSize.minDesktop`
    padding: 0;
  `}
`;

export const Title = styled.h1`
  color: #6629D7;
  text-align: center;
  margin: 0 0 10px 0;
`;

export const ContentText = styled.p`
  font-size: 1.6em;
  text-align: center;
  margin: 20px 0 30px 0;

  ${screenSize.minLargeDesktop`
    font-size: 1.4em;
  `}
`;