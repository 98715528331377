import React, { useState, useEffect, useRef } from "react";
import * as MUIStyled from "./MUIStyled";
import "./styles.css";

function TopNavMUI({
  scrollToRef,
  aboutRef,
  benefitsRef,
  caseStudiesRef,
  electricRef,
  whyNovatedLeaseRef,
  faqRef,
  evDenied,
}) {
  const [value, setValue] = useState("What is a novated lease");
  const [isSticky, setIsSticky] = useState(false);

  const navLinksRefs = useRef([
    { linkName: "What is a novated lease", ref: aboutRef },
    { linkName: "Novated lease benefits", ref: benefitsRef },
    { linkName: "Case study", ref: caseStudiesRef },
    ...(evDenied ? [] : [{ linkName: "Electric vehicles", ref: electricRef }]),
    { linkName: "Why NLA?", ref: whyNovatedLeaseRef },
    { linkName: "FAQ", ref: faqRef },
  ]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const links = navLinksRefs.current;

      for (let i = links.length - 1; i >= 0; i--) {
        const link = links[i];
        const topComponent = link.ref.current.offsetTop - 150;

        if (topComponent <= scrollPosition) {
          setValue(link.linkName);
          break;
        }
      }

      // Sticky behavior
      const topNav = document.getElementById("top-nav");
      if (topNav) {
        const sticky = topNav.offsetTop;
        setIsSticky(scrollPosition > sticky);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navLinksRefs]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    scrollToRef(
      navLinksRefs.current.find((link) => link.linkName === newValue).ref
    );
  };

  return (
    <MUIStyled.CustomBox id="top-nav" className={isSticky ? "sticky" : ""}>
      <MUIStyled.CustomTabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="top navigation"
      >
        {navLinksRefs.current.map((link, index) => (
          <MUIStyled.CustomTab
            key={index}
            value={link.linkName}
            label={link.linkName}
          />
        ))}
      </MUIStyled.CustomTabs>
    </MUIStyled.CustomBox>
  );
}

export default TopNavMUI;
