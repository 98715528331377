import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries";

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`; 

export const Background = styled.div`
  min-height: 400px;
  padding: 50px 40px 30px 40px;

  ${screenSize.minDesktop`
    min-height: 400px;
    width: 100%;
    padding: 50px 40px 40px 40px;
    background: linear-gradient(180deg, #ffffff 50%, #6629D7 50%);
  `}
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  color: #6629D7;
  text-align: center;
  margin: 0;
`;

export const VideoWrapper = styled.div`
  max-width: 900px;
  margin: auto;
`;

export const ContentText = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ContentTextBtn = styled.div`
  display: block;
  margin-bottom: 30px;

  ${screenSize.minDesktop`
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  `}
`;

export const ContentTextBody = styled.p`
  max-width: 100%;
  font-size: 1.6em;
  color: #444248;
  text-align: left;
  font-weight: 500;

  .highlight {
    color: #6629D7;
    font-weight: 600;
  }

  ${screenSize.minDesktop`
    max-width: 55%;
    font-size: 1.4em;
    color: #ffffff;

    .highlight {
      color: #ff874f;
      font-weight: 600;
    }
  `}
`;

export const ButtonWrapper = styled.div` 
    display: block;
    margin: 40px 0 20px 0;
  
  ${screenSize.minDesktop`
    display: flex;
    justify-content: center;
    margin-top: 40px;
  `}
`;