import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const CustomBox = styled(Box)({
});

export const CustomAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const CustomAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  color: '#6629D7',
  backgroundColor: '#ffffff',
  padding: '10px 0',
  flexDirection: 'row',
  borderTop: '2px solid #ECECED',

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    fontFamily: 'Specter',
    fontSize: '22px',
    fontWeight: 550,
    textAlign: 'left',
    justifyContent: 'space-between', 
  },
  '& svg': {
    fontSize: '1em',
    color: '#FF874F',
  },
}));

export const CustomAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '1.5',
  padding: '0 10px 30px 10px',
}));
