import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries";
import Logo from '../../../images/horizontal-logo.png';

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const Background = styled.div`
  background-color: #f8f8f8;
  padding: 40px 0;
`;

export const MainWrapper = styled.div`
  width: 100%;
  padding: 0 40px 22px 40px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #6629d6;
  gap: 0;

  ${screenSize.minDesktop`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #6629d6;
  `}
`;

export const LinksInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: 5px;

  ${screenSize.minDesktop`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
  `}
`;

export const LogoHeader = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-size: 110px 41px;
  width: 110px;
  height: 41px;
  margin-bottom: 20px;

  ${screenSize.minDesktop`
    background-image: url(${Logo});
    background-repeat: no-repeat;
    background-size: 155px 55px;
    width: 155px;
    height: 55px;
  `}
`;

export const MenuLink = styled.p`
  color: #6629D6;
  font-size: 1.2em;
  cursor: pointer;
  margin-bottom: 0;

  ${screenSize.minDesktop`
    color: #6629D6;
    font-size: 1.2em;
    cursor: pointer;
  `}
`;

export const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #6629d6;
    font-size: 1.2em;
    margin-right: 6px;
    margin-bottom: 3px;
  }
`;

export const PhoneNumber = styled.a`
  color: #6629D6;
  font-size: 1.2em;
  text-decoration: none;
  font-family: Specter, "Helvetica Neue", Helvetica, sans-serif;
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding-top: 10px;

  ${screenSize.minDesktop`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  `}
`;

export const CopyrightInnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;

  ${screenSize.minDesktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
  `}
`;

export const CopyrightText = styled.a`
  color: #6629D6;
  font-weight: 500;
  font-size: 1em;
  order: 2; 
  margin-top: 10px;

  ${screenSize.minDesktop`
    color: #6629D6;
    font-weight: 500;
    font-size: 1em;
    order: 0; 
  `}
`;