import React from "react";
import * as Styled from "./TrustPilotSection.styled";
import TrustpilotWidget from './TrustpilotWidget';
import { Helmet } from "react-helmet-async";

const TrustPilotReviews = () => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>

      <Styled.MaxWidth>
        <Styled.PaddingSetter>
          <Styled.TrustpilotReviewsWrapper>
          <TrustpilotWidget 
              dataTemplateId="54ad5defc6454f065c28af8b"
              dataBusinessUnitId="645c4a073dd9fa79f43c4243" 
              dataStyleHeight="240px"
              dataStyleWidth="100%"
              dataTheme="light"
              dataStars="5"
              dataFontFamily="Roboto"
            >
              <a href="https://www.trustpilot.com/review/novatedleaseaustralia.com.au" target="_blank" rel="noreferrer">
                Trustpilot
              </a>
            </TrustpilotWidget>
          </Styled.TrustpilotReviewsWrapper>
        </Styled.PaddingSetter>
      </Styled.MaxWidth>
    </>
  );
};

export default TrustPilotReviews;
