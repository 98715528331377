export const Vehicles = [
  {
    nvicCode: "0EDP24",
    name: "TESLA MODEL Y",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "5D WAGON",
    fuelConsumption: "0",
    range: "533km",
    weeklyPrice: "226",
    cardColor: "gray",
  },
  {
    nvicCode: "0EI224",
    name: "FORD RANGER",
    engineDesc: "6cyl 3.0L TURBO CDI",
    transmission: "10 SP AUTOMATIC",
    body: "DOUBLE CAB P/UP",
    range: "0",
    fuelConsumption: "8.4 L/100km",
    weeklyPrice: "378",
    cardColor: "gray",
  },
  {
    nvicCode: "0DKN24",
    name: "HYUNDAI TUCSON",
    engineDesc: "4cyl 2.0L MULTI POINT F/INJ",
    transmission: "6 SP AUTOMATIC",
    body: "4D WAGON",
    range: "0",
    fuelConsumption: "8.1 L/100km",
    weeklyPrice: "253",
    cardColor: "gray",
  },
  {
    nvicCode: "0E2924",
    name: "BYD SEAL",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D SEDAN",
    range: "460 to 570km",
    fuelConsumption: "0",
    weeklyPrice: "179",
    cardColor: "gray",
  },
  {
    nvicCode: "09I124",
    name: "TOYOTA LANDCRUISER PRADO",
    engineDesc: "4cyl 2.8L DIESEL TURBO F/INJ",
    transmission: "6 SP AUTOMATIC",
    body: "4D WAGON",
    range: "0",
    fuelConsumption: "7.9 L/100km",
    weeklyPrice: "350",
    cardColor: "gray",
  },
  {
    nvicCode: "0E0T24",
    name: "MITSUBISHI OUTLANDER PHEV",
    engineDesc: "4cyl 2.4L MULTI POINT F/INJ HYBRID",
    transmission: "1 SP AUTOMATIC",
    body: "4D WAGON",
    range: "0",
    fuelConsumption: "1.5 L/100km",
    weeklyPrice: "216",
    cardColor: "gray",
  },
  {
    nvicCode: "0DR724",
    name: "TOYOTA HILUX",
    engineDesc: "4cyl 2.8L DIESEL TURBO F/INJ",
    transmission: "6 SP AUTOMATIC",
    body: "DOUBLE CAB P/UP",
    range: "0",
    fuelConsumption: "8.4 L/100km",
    weeklyPrice: "386",
    cardColor: "gray",
  },
  {
    nvicCode: "0EGF24",
    name: "KIA SORENTO",
    engineDesc: "4cyl 2.2L TURBO CDI",
    transmission: "8 SP AUTO DUAL CLUTC",
    body: "4D WAGON",
    range: "0",
    fuelConsumption: "6.1 L/100km",
    weeklyPrice: "285",
    cardColor: "gray",
  },
  {
    nvicCode: "0DOT24",
    name: "MG MG4",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "5D HATCHBACK",
    range: "350 to 530km",
    fuelConsumption: "0",
    weeklyPrice: "146",
    cardColor: "gray",
  },
  {
    nvicCode: "0CGH24",
    name: "ISUZU D-MAX",
    engineDesc: "4cyl 3.0L TURBO CDI",
    transmission: "6 SP AUTO SEQ SPORTS",
    body: "CREW CAB UTILITY",
    range: "0",
    fuelConsumption: "8.0 L/100km",
    weeklyPrice: "355",
    cardColor: "gray",
  },
];
