import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { IoIosArrowForward } from "react-icons/io";

export const CustomTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    height: '90px',
    width: '320px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#FF874F',
  },
  '& .MuiTabScrollButton-root': {
    backgroundColor: '#4B1F9C',
    opacity: 1,
  },
  '& .MuiTabScrollButton-root.Mui-disabled': {
    opacity: 1,
    // borderTop: '1px solid #6629D7',
    borderBottom: '1px solid #6629D7',
    backgroundColor: '#fff',
  },
  '& .MuiTabScrollButton-root.Mui-disabled svg': {
    color: '#6629D7',
    opacity: 0.2,
  },
  '& .MuiTabScrollButton-root svg': {
    color: '#fff',
    fontSize: '28px',
  },
});

export const CustomTab = styled(Tab)(({ theme }) => ({
  height: 90,
  color: '#000000',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 500,
  textAlign: 'left',
  fontFamily: 'specter',
  padding: '20px 20px 20px',
  background: '#ffffff',
  borderRight: '1px solid #6629D7',
  borderBottom: '1px solid #6629D7',

  '&.MuiTab-root': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  '&.Mui-selected': {
    color: '#ffffff',
    backgroundColor: '#4B1F9C',
  },
  
}));

export const CustomBox = styled(Box)({
});

export const CustomAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const CustomAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<IoIosArrowForward />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  height: '100px',
  flexDirection: 'row-reverse',
  borderBottom: '1px solid #6629D7',
  '& .MuiAccordionSummary-expandIconWrapper': {
    fontSize: '1.5em',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    color: '#ffffff',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    fontFamily: 'Specter',
    fontSize: '1.5em',
    fontWeight: 550,
    textAlign: 'left',
  },
  '&.Mui-expanded': {
    color: '#ffffff',
    backgroundColor: '#42119E',
    borderBottom: '3px solid #FF874F',
  },
}));

export const CustomAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '30px 40px 20px 40px',
  borderBottom: '1px solid #6629D7',
}));
