import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries";
import BestDealsImage from "../../../images/bg/best-deal-banner.png";
import BestDealsImageMobile from "../../../images/bg/best-deal-banner-mobile.png";

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`; 

export const Background = styled.div`
  min-height: 200px;
  background-color: #6629D7;

  ${screenSize.minDesktop`
    min-height: 240px;
  `}
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${screenSize.minDesktop`
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;
  `}
`;

export const ImageWrapper = styled.div`
  text-align: center;
`;

export const BestDealsBg = styled.div`
  background-image: url(${BestDealsImageMobile});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
  height: 280px;

  ${screenSize.minDesktop`
    background-image: url(${BestDealsImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    height: 100%;
  `}
`;

export const ContentWrapper = styled.div`
  max-width: 100%;
  padding: 20px 40px;

  ${screenSize.minDesktop`
    max-width: 500px;
    padding: 40px 0 40px 60px;
  `}
`;

export const TitleBadge = styled.div`
  color: #6629d6;
  font-size: 1.5em;
  padding: 15px 25px 10px 25px;
  background: #FFFF00;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;

  ${screenSize.minDesktop`
    font-size: 1.2em;
    margin-top: 0;
  `}
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 2.5em;

  ${screenSize.minDesktop`
    font-size: 2.3em;
  `}
`;