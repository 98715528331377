export const buttonSx = (success) =>({
  height: "55px",
  minWidth: "160px",
  width: "100%",
  boxShadow: "none",
  textTransform: "none",
  fontFamily: "specter",
  bgcolor: success ? "#73D343" : "#FF874F", // Dynamically set bgcolor based on success state
  "&:hover": {
    bgcolor: success ? "#4caf50" : "#EB6630", // Dynamically set bgcolor on hover based on success state
    boxShadow: "none",
  },
  svg: {
    fontSize: "16px",
    marginRight: "10px",
  },
});