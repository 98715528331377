import React from "react";
import * as Styled from "./Buttons.styled";

export const ButtonPurple = ({ labelCTA, onClick, style }) => {
  return (
    <Styled.ButtonWrapper>
      <Styled.ButtonPurple onClick={onClick} style={style}>
        <Styled.TextWhite>{labelCTA}</Styled.TextWhite>
      </Styled.ButtonPurple>
    </Styled.ButtonWrapper>
  );
};

export const ButtonOrange = ({ labelCTA, onClick, style }) => {
  return (
    <Styled.ButtonWrapper>
      <Styled.ButtonOrange onClick={onClick} style={style}>
        <Styled.TextWhite>{labelCTA}</Styled.TextWhite>
      </Styled.ButtonOrange>
    </Styled.ButtonWrapper>
  );
};

export const ButtonOrangeIconRight = ({ labelCTA, icon, onClick, style }) => {
  return (
    <Styled.ButtonWrapper>
      <Styled.ButtonOrange onClick={onClick} style={style}>
        <Styled.TextWhite>
          <Styled.IconWrapper>
            {labelCTA}
            {icon}
          </Styled.IconWrapper>
        </Styled.TextWhite>
      </Styled.ButtonOrange>
    </Styled.ButtonWrapper>
  );
};

export const ButtonPurpleTextLink = ({ labelCTA, onClick, href, target, style, disabled }) => {
  return (
    <Styled.ButtonPurpleTextLink href={href} target={target} onClick={onClick} style={style} disabled={disabled}>
      <p>{labelCTA}</p>
    </Styled.ButtonPurpleTextLink>
  );
};

export const ButtonOrangeTextLink = ({ labelCTA, onClick, href, target, style }) => {
  return (
    <Styled.ButtonOrangeTextLink href={href} target={target} onClick={onClick} style={style}>
      <p>{labelCTA}</p>
    </Styled.ButtonOrangeTextLink>
  );
};
