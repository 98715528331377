export const Benefits = [
  {
    frontTitle: "GST savings on car purchase",
    frontContent:`Instantly save 10% on the purchase price of the vehicle, plus any extras and accessories included in the up-front cost.`,
    slideTitle: "GST savings on car purchase",
    slideContent:`If you buy your car through a novated lease, you will save on GST on the purchase price of the vehicle, plus any eligible extras and accessories you decide to add. (up to a maximum of $6,191.64)`,
  },
  {
    frontTitle: "Pre-tax salary for repayments",
    frontContent:`Payments made on your behalf by your employer using your pre-tax salary, which reduces your taxable income.`,
    slideTitle: "Pre-tax salary for repayments",
    slideContent:`For example, if your salary is $100,000 per year and your pre-tax lease repayment is $20,000 - you only pay income tax on $80,000!`,
  },
  {
    frontTitle: "Save GST on car running costs",
    frontContent: `Don't pay GST on your car's running costs, including fuel or electricity, rego & CTP, servicing & maintenance, tyres and comprehensive insurance.`,
    slideTitle: "Save GST on car running costs",
    slideContent:`Novated leasing allows you to bundle your driving costs into one simple, regular, GST-exclusive repayment.`,
  },
];