import React from "react";
import * as Styled from "./Inputs.styled";
import * as MUIStyled from "./MUIStyled";

export const InputText = React.forwardRef(({ label, onChange, onBlur, onFocus, onPaste, value, style, inputProps, error, helperText }, ref) => {
  return (
    <Styled.InputWrapper>
      <MUIStyled.CustomTextField
        inputRef={ref} // Apply the ref to the underlying input element
        id={error ? "outlined-error-helper-text" : "outlined-basic"}
        error={error} // Set error to true when validation fails
        helperText={helperText}
        label={label}
        variant="outlined"
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={onPaste}
        value={value}
        style={style}
        inputProps={inputProps}
      />
    </Styled.InputWrapper>
  );
});

export const CheckboxLabel = ({ label, onChange, value, style }) => {
  return (
    <Styled.InputWrapper>
      <MUIStyled.CustomFormControlLabel
        control={<MUIStyled.CustomCheckboxLabel />}
        label={label}
        onChange={onChange}
        value={value}
        style={style}
      />
    </Styled.InputWrapper>
  );
};
