import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries";

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const PaddingSetter = styled.div`
  padding: 40px 0;

  ${screenSize.minDesktop`
    padding: 40px 20px;
  `}
`;

export const TrustpilotReviewsWrapper = styled.div`
  position: relative;
`;

export const TrustpilotReviews = styled.div`
  height: 400px;
`;