import React, { useState, useEffect, useRef } from "react";
import Header from "../Header";
import TopNavigationMobile from "../TopNav/TopNavMobile";
import TopNav from "../TopNav/TopNav";
import Footer from "../Footer";
import PromoCarousel from "../../sections/PromoCarousel";
import SignUp from "../../sections/Signup";
import VehicleComparison from "../../sections/VehicleComparison";
import Top10LeasedCarousel from "../../sections/Top10LeasedCarousel";
import Top10LeasedEVCarousel from "../../sections/Top10LeasedEVCarousel";
import WhyNovatedLeaseAustralia from "../../sections/WhyNovatedLeaseAustralia";
import FAQSection from "../../sections/FAQSection";
import NovatedLeaseBenefits from "../../sections/NovatedLeaseBenefits";
import CaseStudies from "../../sections/CaseStudies";
import AboutNovatedLease from "../../sections/AboutNovatedLease";
import LeaseCashLoanSection from "../../sections/LeaseCashLoanSection";
import BestDealBanner from "../../sections/BestDealBanner";
import TrustPilotSection from "../../sections/TrustPilotSection";
import ScrollToTop from "../ScrollToTop";
import IntercomChatBox from "../IntercomChatBox/IntercomChatBox";
import Loading from "../../elements/Loading/Loading";

function Main({ email, codeDigits, signupAgreed }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 503);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 993);
  const [drawerState, setDrawerState] = useState({ right: false });
  const aboutRef = useRef(null);
  const benefitsRef = useRef(null);
  const caseStudiesRef = useRef(null);
  const electricRef = useRef(null);
  const whyNovatedLeaseRef = useRef(null);
  const faqRef = useRef(null);
  // Company settings
  const [loading, setLoading] = useState(true);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [evDenied, setEvDenied] = useState(false); // (0)false = show ev
  const [iceDenied, setIceDenied] = useState(false); // (0)false = show ice

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 993);
      setIsMobile(window.innerWidth <= 503);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // get company settings from localStorage
  useEffect(() => {
    const companySettings = JSON.parse(
      localStorage.getItem("nlaPortalTokenExp")
    );

    if (companySettings !== null) {
      // Load company settings from localStorage if key exists
      setCompanyLogo(companySettings.companyLogo);
      setCompanyId(companySettings.companyId);
      setCompanyName(companySettings.companyName);
      setEvDenied(companySettings.evDenied);
      setIceDenied(companySettings.iceDenied);
      setLoading(false);
    }
  }, [companyLogo, companyId, companyName, evDenied, iceDenied]);

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  if (loading) {
    // Render loading state if data is still being fetched
    return <Loading />;
  }

  return (
    <main>
      <header>
        <Header
          isTablet={isTablet}
          toggleDrawer={toggleDrawer}
          companyLogo={companyLogo}
          companyName={companyName}
        />
        {!isTablet ? (
          <TopNav
            scrollToRef={scrollToRef}
            aboutRef={aboutRef}
            benefitsRef={benefitsRef}
            caseStudiesRef={caseStudiesRef}
            electricRef={electricRef}
            whyNovatedLeaseRef={whyNovatedLeaseRef}
            faqRef={faqRef}
            evDenied={evDenied}
          />
        ) : (
          <TopNavigationMobile
            toggleDrawer={toggleDrawer}
            drawerState={drawerState}
            scrollToRef={scrollToRef}
            aboutRef={aboutRef}
            benefitsRef={benefitsRef}
            caseStudiesRef={caseStudiesRef}
            electricRef={electricRef}
            whyNovatedLeaseRef={whyNovatedLeaseRef}
            faqRef={faqRef}
            evDenied={evDenied}
          />
        )}
      </header>
      <article>
        <PromoCarousel companyLogo={companyLogo} />
        <SignUp companyId={companyId} />
        <TrustPilotSection />
        <BestDealBanner />
        <AboutNovatedLease ref={aboutRef} />
        <NovatedLeaseBenefits ref={benefitsRef} />
        {!iceDenied && (
          <Top10LeasedCarousel isTablet={isTablet} iceDenied={iceDenied} />
        )}
        <CaseStudies ref={caseStudiesRef} isTablet={isTablet} />
        <Top10LeasedEVCarousel
          ref={electricRef}
          isTablet={isTablet}
          evDenied={evDenied}
        />
        <LeaseCashLoanSection />
        <VehicleComparison isMobile={isMobile} isTablet={isTablet} />
        <WhyNovatedLeaseAustralia
          isTablet={isTablet}
          ref={whyNovatedLeaseRef}
        />
        <FAQSection ref={faqRef} />
        <ScrollToTop />
        <IntercomChatBox />
      </article>
      <footer>
        <Footer />
      </footer>
    </main>
  );
}

export default Main;
