import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../Login";
import Main from "../Main";
import CodeVerify from "../Login/CodeVerify";
import Loading from "../../elements/Loading/Loading";

import "./fonts/specter.css";
import "./fonts/calibre.css";

function Layout() {
  const [email, setEmail] = useState("");
  const [codeDigits, setCodeDigits] = useState(["", "", "", "", "", ""]);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [signupAgreed, setSignupAgreed] = useState(true);

  useEffect(() => {
    const tokenExpiration = localStorage.getItem("nlaPortalTokenExp");
    const currentTime = new Date().getTime();
    const companySettings = JSON.parse(
      localStorage.getItem("nlaPortalTokenExp")
    );

    if (tokenExpiration === null) {
      // token has not been created.
      setIsEmailValid(false);
      setIsCodeValid(false);
      setIsLoading(false);
      return;
    } else {
      const expiration = companySettings.expiration;

      if (currentTime > parseInt(expiration)) {
        // token has expired
        setIsEmailValid(false);
        setIsCodeValid(false);
        setIsLoading(false);
        localStorage.removeItem("nlaPortalTokenExp");
        return;
      }
      if (currentTime < parseInt(expiration)) {
        // token is valid
        setIsEmailValid(true);
        setIsCodeValid(true);
        setIsLoading(false);
        return;
      }
    }
  }, []);

  

  return (
    <BrowserRouter>
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <>
                {!isEmailValid ? (
                  <Login
                    setIsEmailValid={setIsEmailValid}
                    email={email}
                    setEmail={setEmail}
                    signupAgreed={signupAgreed}
                    setSignupAgreed={setSignupAgreed}
                  />
                ) : (
                  <>
                    {!isCodeValid ? (
                      <CodeVerify
                        setIsCodeValid={setIsCodeValid}
                        email={email}
                        codeDigits={codeDigits}
                        setCodeDigits={setCodeDigits}
                        signupAgreed={signupAgreed}
                      />
                    ) : (
                      <Main
                        email={email}
                        codeDigits={codeDigits}
                        signupAgreed={signupAgreed}
                      />
                    )}
                  </>
                )}
              </>
            }
          ></Route>
          <Route path="/logout" element={<Navigate to="/" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default Layout;
