import styled from 'styled-components';
import screenSize from '../../../styles/mediaQueries';
import RibbonBg from '../../../images/bg/ribbon-orange-vert-right.png';

export const BlockWrapper = styled.div``; 

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;
 
  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const Background = styled.div` 
  min-height: 400px;
  background-color: #6629D7;
  padding: 60px 40px;

  ${screenSize.minDesktop`
    background-image: url(${RibbonBg});
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    padding: 60px 60px;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  color: #ffffff;
  text-align: center;
  margin: 0 0 10px 0;
`;

export const SubTitle = styled.p`
  color: #ffffff;
  font-size: 1.6em;
  text-align: center;
  margin-bottom: 40px;

`;

export const AccordionContentWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const AccordionContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const WhyNLAImgWrapper = styled.div` 
  width: 200px;
  height: 460px;
`;

export const AccordionContentWhyNLAImg = styled.img` 
  width: 200px;
  height: 460px;
`;

export const ContentInnerWrapper = styled.div`
  height: 460px;
  background-color: #ffffff;
  padding: 0 50px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ContentInnerTitle = styled.h5`
  color: #6629d6;
  font-size: 22px;
  margin: 0 0 30px 0;

  ${screenSize.minDesktop`
    font-size: 24px;
  `}
`;

export const ContentInnerCopy = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  ${screenSize.minLargeDesktop`
    font-size: 20px;
  `}
`;

export const highlight = styled.span`
  fontWeight: 'bold',
  color: 'red',
`;
