import React from "react";
import * as Styled from "./WhyNovatedLeaseAustralia.styled";
import * as MUIStyled from "./MUIStyled";
import { ContentItems } from "./ContentItemsData";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MUIStyled.CustomBox sx={{ p: 3 }}>
          {children}
        </MUIStyled.CustomBox>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function WhyNovatedLeaseAustralia() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Styled.MaxWidth>
      <MUIStyled.CustomBox
        sx={{
          bgcolor: "rgba(255, 255, 255, 0.3)",
          display: "flex",
          height: 610,
        }}
      >
        <MUIStyled.CustomTabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            minWidth: 320,
            maxWidth: 320,
            borderRight: 1,
            borderColor: "#6629D7",
            bgcolor: "#ffffff",
          }}
        >
          {ContentItems.map((item, indice) => (
            <MUIStyled.CustomTab
              key={indice}
              label={item.selectorText}
              {...a11yProps(indice)}
            />
          ))}
        </MUIStyled.CustomTabs>
        <MUIStyled.CustomBox
          sx={{ width: "85%", display: "flex", alignItems: "center" }}
        >
          {ContentItems.map((item, indice) => (
            <TabPanel key={indice} value={value} index={indice}>
              <Styled.AccordionContent>
                <Styled.WhyNLAImgWrapper>
                 <Styled.AccordionContentWhyNLAImg src={item.image} alt={item.title} />
                </Styled.WhyNLAImgWrapper>
                <Styled.ContentInnerWrapper>
                  <Styled.BlockWrapper>
                    <Styled.ContentInnerTitle>
                      {item.title}
                    </Styled.ContentInnerTitle>
                    <Styled.ContentInnerCopy>
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </Styled.ContentInnerCopy>
                  </Styled.BlockWrapper>
                </Styled.ContentInnerWrapper>
              </Styled.AccordionContent>
            </TabPanel>
          ))}
        </MUIStyled.CustomBox>
      </MUIStyled.CustomBox>
    </Styled.MaxWidth>
  );
}

export default WhyNovatedLeaseAustralia;
