import VehicleFuelBadge from "../../../images/bg/comparison-mazda-badge.png";
import VehicleFuelImgTop from "../../../images/bg/comparison-mazda6.png";
import VehicleHybridBadge from "../../../images/bg/comparison-corolla-badge.png";
import VehicleHybridImgTop from "../../../images/bg/comparison-corolla.png";
import VehicleEVBadge from "../../../images/bg/comparison-tesla-badge.png";
import VehicleEVImgTop from "../../../images/bg/comparison-tesla.png";

export const Vehicles = [
  {
    carImgTop: VehicleFuelImgTop,
    carBadge: VehicleFuelBadge,
    name: "Mazda CX-5 Maxx",
    engineType: "Petrol",
    rrpPrice: "$39,990.00",
    priceExGST: "$36,552.64",
    GST: "$3,437.36",
    stampDuty: "$1,137.00",
    registrationCtp: "$1,042.00",
    LuxuryCarTax: "$0.00",
    gstSavingsPrice: "$3,437.36",
    weeklyCost: "$222.00",
    annuallyCost: "$11,559.00",
  },
  {
    carImgTop: VehicleHybridImgTop,
    carBadge: VehicleHybridBadge,
    name: "Toyota RAV-4 GX Hybrid 2WD",
    engineType: "Hybrid",
    rrpPrice: "$46,612.00",
    priceExGST: "$42,590.19",
    GST: "$4,021.91",
    stampDuty: "$1,329.00",
    registrationCtp: "$1,042.00",
    LuxuryCarTax: "$0.00",
    gstSavingsPrice: "$4,021.91",
    weeklyCost: "$241.00",
    annuallyCost: "$12,530.44",
  },
  {
    carImgTop: VehicleEVImgTop,
    carBadge: VehicleEVBadge,
    name: "Tesla Model Y RWD",
    engineType: "Electric",
    rrpPrice: "$70,817.00",
    priceExGST: "$64,707.91",
    GST: "$6,109.09",
    stampDuty: "$2,460.00",
    registrationCtp: "$1,157.00",
    LuxuryCarTax: "$0.00",
    gstSavingsPrice: "$6,109.09",
    weeklyCost: "$224.00",
    annuallyCost: "$11,633.00",
  },
];