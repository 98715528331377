import styled, { keyframes } from 'styled-components';
import screenSize from '../../../styles/mediaQueries';
import RibbonBg from "../../../images/bg/ribbon-gray-hor-bottom.svg";

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;
 
  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const Background = styled.div`
  background-color: #F8F8F8;
  margin: 60px 0 20px 0;
  display: flex;
  justify-content: center;

  ${screenSize.minDesktop`
    background-image: url(${RibbonBg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  `}
`;

export const SignupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 50px 0;
`;

export const SignupTitle = styled.h1`
  max-width: 580px;
  color: #6629D7;
  font-size: 2.4em;
  margin: 20px 0;
  text-align: center;
`;

export const NotificationCard = styled.div`
  width: 85%;
  height: 146px;
  background: #fff;
  border: 1px solid #E2E2E2;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;

  display: flex;
  align-items: center;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  animation: ${fadeIn} 0.8s ease-out;
`;

export const CardCheckWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  animation: ${fadeIn} 0.5s ease-out;
`;

export const CardCopy = styled.p`
  width: 84%;
  font-size: 16px;
  text-align: left;

  ${screenSize.minDesktop`
    width: 90%;
  `}
`;

export const EmailIcon = styled.div`
  height: 42px;
  width: 42px;
  background-color: #6629D7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg{
    color: #fff;
    font-size: 16px;
  }
`;



export const CheckIcon = styled.div`
  height: 42px;
  width: 50px;
  background-color: #73D343;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg{
    color: #fff;
    font-size: 16px;
  }
`;

export const SignupFormWrapper = styled.form`
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  ${screenSize.minDesktop`
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  `}
`;

export const SignupFormButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${screenSize.minDesktop`
    width: auto;
  `}
`;

