import React from "react";
import * as MUIStyled from "./MUIStyled";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

function TopNavMobile({
  toggleDrawer,
  drawerState,
  scrollToRef,
  aboutRef,
  benefitsRef,
  caseStudiesRef,
  electricRef,
  whyNovatedLeaseRef,
  faqRef,
  evDenied,
}) {
  const NavLinks = [
    {
      linkName: "What is a novated lease",
      linkUrl: aboutRef,
    },
    {
      linkName: "Novated lease benefits",
      linkUrl: benefitsRef,
    },
    {
      linkName: "Case study",
      linkUrl: caseStudiesRef,
    },
    ...(evDenied ? [] : [{ linkName: "Electric vehicles", linkUrl: electricRef }]),
    // {
    //   linkName: "Lease calculator",
    //   linkUrl: faqRef,
    // },
    {
      linkName: "Why NLA?",
      linkUrl: whyNovatedLeaseRef,
    },
    {
      linkName: "FAQ",
      linkUrl: faqRef,
    },
  ];

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MUIStyled.LinksWrapperM>
        {NavLinks.map((link, indice) => {
          return (
            <MUIStyled.LinkM key={indice}>
              <NavLink
                to="#"
                onClick={() => {
                  scrollToRef(link.linkUrl);
                }}
                style={{ fontSize: '1.6em'}}
              >
                {link.linkName}
              </NavLink>
            </MUIStyled.LinkM>
          );
        })}
      </MUIStyled.LinksWrapperM>
    </Box>
  );

  return (
    <div>
      <>
        <Drawer
          anchor="right"
          open={drawerState["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </>
    </div>
  );
}

export default TopNavMobile;
