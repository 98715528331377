import { css } from 'styled-components';

export const MIN_MOBILE = 375;
export const MIN_LARGE_MObile = 500;
export const MIN_TABLET = 768;
export const MIN_DESKTOP = 992;
// export const HD = 1920;
export const MIN_DESKTOP_LG = 1360;

export const sizes = {
  minMobile: `@media print, screen and (min-width: ${MIN_MOBILE}px)`,
  minLargeMobile: `@media print, screen and (min-width: ${MIN_LARGE_MObile}px)`,
  minTablet: `@media print, screen and (min-width: ${MIN_TABLET}px)`,
  minDesktop: `@media print, screen and (min-width: ${MIN_DESKTOP}px)`,
  minLargeDesktop: `@media print, screen and (min-width: ${MIN_DESKTOP_LG}px)`,
};

// Snippet sourced from: https://medium.com/@samuelresua/easy-media-queries-in-styled-components-690b78f50053
const screenSize = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    ${sizes[label]} {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default screenSize;
