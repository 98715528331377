import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries";

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`; 

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  ${screenSize.minDesktop`
    display: grid;
    grid-gap: 40px;
    align-items: top;
    grid-template-columns: repeat(2, 1fr);
    margin: 90px 40px;
  `}
`;

export const ImageWrapper = styled.div`
  text-align: center;
  order: 1;

  ${screenSize.minDesktop`
    text-align: center;
    order: 0;
  `}
`;

export const LeaseCashLoanImg = styled.img`
  width: auto;
  height: 380px;

  ${screenSize.minDesktop`
    height: 520px;
  `}
`;

export const ContentWrapper = styled.div`
  padding: 30px 40px;
  order: 0;

  ${screenSize.minLargeDesktop`
    padding: 50px 70px;
    order: 1;
  `}
`;

export const Title = styled.h1`
  color: #6629D7;
  font-size: 2.7em;
  margin: 0 0 30px 0;
`;

export const Content = styled.p`
  font-size: 1.5em;

  ${screenSize.minLargeDesktop`
    font-size: 1.2em;
  `}
`;