import React, { useState } from "react";
import * as Styled from "./NovatedLeaseBenefits.styled";
import { Benefits } from "./BefenitsData";
import { IoIosArrowForward } from "react-icons/io";

const CardComponent = () => {
  const [activeCard, setActiveCard] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveCard(index);
  };

  const handleMouseLeave = () => {
    setActiveCard(null);
  };

  return (
    <Styled.CardContainer id='grid'>
      {Benefits.map((content, index) => (
        <Styled.Card
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <Styled.Content id={index} className={index === 1 ? 'lightPurpleBackground' : ''}>
            <Styled.BlockWrapper>
              <Styled.CardTitle>{content.frontTitle}</Styled.CardTitle>
              <Styled.CardContent>{content.frontContent}</Styled.CardContent>
            </Styled.BlockWrapper>
            <Styled.CardFooter>
              <IoIosArrowForward />
            </Styled.CardFooter>
          </Styled.Content>
          <Styled.TopContent $show={activeCard !== index}>
            <Styled.BlockWrapper>
              <Styled.CardTitle>{content.slideTitle}</Styled.CardTitle>
              <Styled.CardContent>{content.slideContent}</Styled.CardContent>
            </Styled.BlockWrapper>
          </Styled.TopContent>
        </Styled.Card>
      ))}
      <Styled.BenefitsBg />
    </Styled.CardContainer>
  );
};

export default CardComponent;
