import React from "react";
import * as Styled from "./LeaseCashLoanSection.styled";
import LeaseCashLoanImage from "../../../images/bg/lease-cash-loan.png";

function LeaseCashLoanSection() {
  return (
    <Styled.MaxWidth>
      <Styled.MainWrapper>
        <Styled.ImageWrapper>
          <Styled.LeaseCashLoanImg
            src={LeaseCashLoanImage}
            alt="lease vs cash vs loan"
          />
        </Styled.ImageWrapper>
        <Styled.ContentWrapper>
          <Styled.Title>
            Electric vehicle savings with a novated lease
          </Styled.Title>
          <Styled.Content>
            The FBT exemption on novated leases will make a significant
            difference to the overall cost of an EV and paying for running
            costs. Here's an example cost comparison for a 2024 Tesla Model 3
            (including running costs), financed with a novated lease, a car loan
            and paid for outright using cash. The calculation is based on a
            5-year term and factors in the fringe benefits tax exemption, plus
            the GST savings on the vehicle purchase and running costs available
            with a novated lease. The calculation assumes a driver based in NSW
            with annual pre-tax salary of $120,000 and a car loan interest rate
            of 7.5% p.a. Running costs based on 15,000km driven per year and
            include insurance, registration, servicing and tyres.
          </Styled.Content>
        </Styled.ContentWrapper>
      </Styled.MainWrapper>
    </Styled.MaxWidth>
  );
}

export default LeaseCashLoanSection;
