import React, { useState, useEffect } from "react";
import * as Styled from "./PromoCarousel.styled";
import NLALogo from "../../../images/horizontal-logo.png";
import VehicleImg1 from "../../../images/promo/Partner-MG4.png";
import VehicleImg2 from "../../../images/promo/Partner-corolla.png";
import VehicleImg3 from "../../../images/promo/Partner-hyundai.png";
import VehicleImg4 from "../../../images/promo/Partner-tesla.png";
import { FaHeart } from "react-icons/fa";

function SlidePartnered() {
  const [randomImageUrl, setRandomImageUrl] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const imageUrls = [
      VehicleImg1,
      VehicleImg2,
      VehicleImg3,
      VehicleImg4,
    ];
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    const randomUrl = imageUrls[randomIndex];
    setRandomImageUrl(randomUrl);
  }, []); 

  useEffect(() => {
    const companySettings = JSON.parse(localStorage.getItem("nlaPortalTokenExp"));
    setCompanyLogo(companySettings.companyLogo);
    setCompanyName(companySettings.companyName);
  },[]);

  useEffect(() => {
    // load states
  },[companyLogo, companyName]);

  return (
    <Styled.PartnerContentWrapper>
      <Styled.ContentWrapper>
        <Styled.PartnerContent>
          <Styled.YellowTopArtfacts />
          <Styled.PartnerTitleWrapper>
            <Styled.PartnerName>{companyName}</Styled.PartnerName>
            <Styled.PartnerTitle>
              have partnered with Novated Lease Australia
            </Styled.PartnerTitle>
          </Styled.PartnerTitleWrapper>
          <Styled.PartnerLogosWrapper>
            <Styled.PartnerLogo>
              <img src={companyLogo} alt={companyName} />
            </Styled.PartnerLogo>
            <FaHeart />
            <Styled.PartnerLogo>
              <img src={NLALogo} alt="Novated Lease Australia" />
            </Styled.PartnerLogo>
            <FaHeart />
            <Styled.PartnerLogo>
              {randomImageUrl && <img src={randomImageUrl} alt="vehicles" className="vehicle" />}
            </Styled.PartnerLogo>
          </Styled.PartnerLogosWrapper>
        </Styled.PartnerContent>
      </Styled.ContentWrapper>
    </Styled.PartnerContentWrapper>
  );
}

export default SlidePartnered;
