import styled from 'styled-components';
import screenSize from '../../../styles/mediaQueries';
import YellowArtfacts from '../../../images/bg/comparison-yellow-artfacts.svg';

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;
 
  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`; 

export const ComparisonBackground = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #73D343 75%, #ffffff 25%);
  padding-top: 30px;
  margin-bottom: 60px;
`;

export const YellowTopArtfacts = styled.div`
  background-image: url(${YellowArtfacts});
  background-repeat: no-repeat;

  ${screenSize.minDesktop`
    background-size: contain;
    background-position: top center;
    height: 140px;
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px 0;

  ${screenSize.minDesktop`
    padding: 0;
  `}
`;

export const Title = styled.h1`
  max-width: 650px;
  color: #ffffff;
  text-align: center;
  margin: 0 0 10px 0;
`;

export const ContentText = styled.div`
  max-width: 650px;
  text-align: center;
`;

export const ContentTextTitle = styled.h6`
  color: #6629d6;
  font-size: 1.6em;
  line-height: 32px;
  margin: 20px 0 0 0;

  ${screenSize.minLargeDesktop`
    font-size: 1.4em;
  `}
`;

export const ContentTextBody = styled.p`
  color: #6629d6;
  font-size: 1.6em;

  ${screenSize.minLargeDesktop`
    font-size: 1.4em;
  `}
`;

export const VehiclesWrapper = styled.div`
  height: 820px;
  padding-top: 150px;

  ${screenSize.minLargeDesktop`
    height: 860px;
  `}
`;

export const VehicleImgTopWrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  margin-top: -120px;

  ${screenSize.minLargeDesktop`
    margin-top: -160px;
  `}
`;

export const VehicleVersusWrapper = styled.div`
  position: absolute;
  z-index: 3;
  margin-top: -50px;

  ${screenSize.minLargeDesktop`
    margin-top: -65px;
  `}
`;

export const VehicleCard = styled.div`
  position: relative;
  min-width: 400px;
  min-height: 300px;
  border-radius: 20px;
  border: 1px solid;
  border-color: ${(props) =>
    props.$enginetype === 'Petrol'
      ? '#FF874F' 
      : props.$enginetype === 'Hybrid'
      ? '#6629D7' 
      : '#50BA1B'};
  background: ${(props) =>
    props.$enginetype === 'Petrol'
      ? '#FF874F'
      : props.$enginetype === 'Hybrid'
      ? '#6629D7'
      : '#50BA1B'}; 
  font-family: Specter, Helvetica Neue, Helvetica, sans-serif;

  @media only screen and (max-width: 1440px) {
    min-width: 320px;
  }
  @media only screen and (max-width: 1024px) {
    min-width: 300px;
  }
  @media only screen and (max-width: 993px) {
    min-width: 250px;
  }
  @media only screen and (max-width: 823px) {
    min-width: 200px;
  }
  @media only screen and (max-width:503px){
    min-width: 400px;
  }
  @media only screen and (max-width:430px){
    min-width: 350px;
  }
  @media only screen and (min-width:375px) and (max-width:414px) {
    min-width: 310px;
  }
`;

export const VehicleCardHeaderWrapper = styled.div`
  color: #ffffff;
  background: ${(props) =>
    props.$enginetype === 'Petrol'
      ? '#FF874F'
      : props.$enginetype === 'Hybrid'
      ? '#6629D7'
      : '#50BA1B'}; 
  padding: 25px 20px 20px 20px;
  border-radius: 20px 20px 0 0;

  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.h6`
  width: 320px;
  height: 56px;
  color: #ffffff;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;

  @media (max-width: 1440px) {
    width: 320px;
  } 
  @media (max-width: 1300px) {
    width: 200px;
  } 
  @media (max-width: 1024px) {
    width: 200px;
  } 
  @media (max-width: 993px) {
    width: 200px;
  } 
  @media (max-width: 768px) {
    width: 150px;
  } 
  @media (max-width: 502px) {
    width: 200px;
  }
  @media (max-width: 430px) {
    width: 200px;
  }  
`;

export const VehicleCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  background: #ffffff;
`;

export const VehicleContentInfo = styled.div`
  color: #ffffff;
  background: ${(props) =>
    props.$enginetype === 'Petrol'
      ? '#F06437'
      : props.$enginetype === 'Hybrid'
      ? '#42119E'
      : '#008905'}; 
  padding: 12px 20px 10px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PurchasePriceLabel = styled.div`
  max-width: 150px;
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  margin-top: 4px;

  ${screenSize.minLargeDesktop`
    font-size: 1em;
  `}
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
`;

export const Price = styled.h6`
  color: #ffffff;
  margin: 0 0 5px 0;
  .disclaimer {
    font-size: 0.9em;
  }
`;

export const Drive = styled.div`
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;

  ${screenSize.minLargeDesktop`
    font-size: 1em;
  `}
`;

export const VehicleContentInfoEngineBadge = styled.div`
  color: ${(props) =>
    props.$enginetype === 'Petrol'
      ? '#FF874F'
      : props.$enginetype === 'Hybrid'
      ? '#6629D7'
      : '#50BA1B'}; 
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  background: #ffffff;
  padding: 8px 15px 4px 15px;
  border-radius: 6px;
`;

export const VehicleContentDetails = styled.div`
  padding: 12px 20px 10px 20px;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VehicleContentLabel = styled.div`
  p {
    color: #9692A6;
    font-size: 1.2em;
    text-align: left;
  }
`;

export const VehicleContentDescription = styled.div`
  p {
    font-size: 1.2em;
    text-align: right;
  }
`;

export const VehicleCardFooter = styled.div`
  color: #ffffff;
  background: ${(props) =>
    props.$enginetype === 'Petrol'
      ? '#FF874F'
      : props.$enginetype === 'Hybrid'
      ? '#6629D7'
      : '#50BA1B'}; 
  padding: 25px 20px 20px 20px;
  border-radius: 0 0 20px 20px;
`;

export const VehicleCardFooterTitle = styled.h6`
  color: #ffffff;
  font-size: 1.4em;
  text-align: center;
  text-transform: uppercase;
  margin: 0;

  ${screenSize.minLargeDesktop`
    font-size: 1em;
  `}
`;

export const VehicleCardFooterPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const PeriodBadge = styled.div`
  min-width: 80px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 5px 4px 5px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  margin-bottom: 8px;
`;

export const Disclaimer = styled.p`
  font-size: 18px;
  text-align: left;
  margin: 20px 0 20px 35px;
`;



