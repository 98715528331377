import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries";

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const MainWrapper = styled.div`
  height: 700px;
`;

export const Top10VehiclesWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  margin: 40px 60px;
`;

export const VehicleCard = styled.div`
  width: 304px;
  min-height: 340px;
  border-radius: 20px;
  font-family: Specter, Helvetica Neue, Helvetica, sans-serif;

  @media (max-width: 1496px) {
    width: 264px;
  }
  @media (max-width: 1280px) {
    width: 244px;
  } 
  @media (max-width: 502px) {
    width: 304px;
  }      
`;

export const VehicleCardContent = styled.div`
  min-height: 200px;
  color: ${(props) =>
    props.$cardcolor === "gray"
      ? "#444248"
      : props.$cardcolor === "green"
      ? "#ffffff"
      : "#ffffff"};
  background-color: ${(props) =>
    props.$cardcolor === "gray"
      ? "#F8F8F9"
      : props.$cardcolor === "green"
      ? "#73D343"
      : "#73D343"};
  border: 2px solid;
  border-color: #E2E9EE;
  border-radius: 20px;
  padding-bottom: 20px;
  transition: background-color 0.2s ease-out;

  h6 {
    color: ${(props) =>
      props.$cardcolor === "gray"
      ? "#444248"
      : props.$cardcolor === "green"
      ? "#ffffff"
      : "#ffffff"};
  }

  &:hover {
    color: ${(props) =>
      props.$cardcolor === "gray"
        ? "#ffffff"
        : props.$cardcolor === "green"
        ? "#ffffff"
        : "#ffffff"};

    background-color: ${(props) =>
      props.$cardcolor === "gray"
        ? "#6629D7"
        : props.$cardcolor === "green"
        ? "#008905"
        : "#008905"};

    h6 {
      color: ${(props) =>
        props.$cardcolor === "gray"
        ? "#ffffff"
        : props.$cardcolor === "green"
        ? "#ffffff"
        : "#ffffff"};
    }
    cursor: pointer;
  }
`;

export const VehicleCardHeader = styled.div`
  height: 200px;
`;

export const HeaderImg = styled.span`
`;

export const VehicleImg = styled.img`
`;

export const VehicleTitle = styled.h6`
  height: 50px;
  color: ${(props) =>
    props.$cardcolor === "gray"
      ? "#444248"
      : props.$cardcolor === "green"
      ? "#ffffff"
      : "#ffffff"};

  font-size: 1.5em;
  text-align: center;
  margin: 0;
  padding: 20px 20px 0 20px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  ${screenSize.minDesktop`
    font-size: 1.2em;
  `}
`;

export const VehicleContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  margin-top: 25px;
  padding: 0 20px;
`;

export const VehiclePriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 0 20px 10px;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 15px;

  svg {
    font-size: 1.5em;
  }

  ${screenSize.minDesktop`
    svg {
      font-size: 1.2em;
    }
  `}
`;

export const DetailsRowLabel = styled.div`
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 600;
  
  ${screenSize.minDesktop`
    font-size: 0.7em;
  `}
`;

export const DetailsRowContent = styled.div`
  max-width: 98px;
  min-height: 30px;
  font-size: 1.1em;
  text-transform: uppercase;

  .rangeNote {
    font-size: 0.8em;
    font-style: italic;
    text-transform: capitalize;
    white-space: nowrap;
  }

  ${screenSize.minDesktop`
    font-size: 0.9em;
  `}
`;

export const PriceTag = styled.h6`
  margin: 0;
  font-size: 1.8em;

  ${screenSize.minLargeDesktop`
    font-size: 1.6em;
  `}
`;

export const NotesStartFromTag = styled.div`
  font-size: 1em;
  margin-top: 5px;
  text-align: right;
`;

export const NotesWeeklyTag = styled.div`
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 5px;
`;

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export const SliderArrowsWrapper = styled.div`
  width: 60%;
  height: 60px;
  position: absolute;
  z-index: 3;
  padding: 0 30px;
  font-size: 2.5em;
  margin-top: 270px;
`;

export const SliderArrowsLeft = styled.div`
  margin-left: 0;
`;

export const SliderArrowsRight = styled.div`
  margin-left: 100%;
`;