import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export const CustomTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  '& .MuiTab-root': {
    fontWeight: 600,
    fontFamily: 'Specter, Helvetica Neue, Helvetica, sans-serif',
    color: '#6629d6',
    padding: '20px',
  },
  '& .MuiTab-root:hover': {
    color: '#6E42F7',
  },
  '& .Mui-selected': {
    color: '#6629d6 !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#6629d6',
    height: 4,
  },
});

export const CustomTab = styled(Tab)({
  fontSize: '1.25em',
  textTransform: 'none',
});

export const CustomBox = styled(Box)({
  width: "100%",
 background: '#ffffff',
});


// Mobile Styled
export const LinksWrapperM = styled(Box)({
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
 });

 export const LinkM = styled(Box)({
  fontWeight: 600,
  fontFamily: 'Specter, Helvetica Neue, Helvetica, sans-serif',
  color: '#6629d6',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  
  '& :hover': {
    color: '#6E42F7',
  },
 });