import React, { useState, useEffect } from "react";
import * as Styled from "./PromoCarousel.styled";
import SlideTextImage from "./SlideTextImage";
import SlideTextImageNoPrice from "./SlideTextImageNoPrice";
import SlideImage from "./SlideImage";
import SlidePartnered from "./SlidePartnered";
import { Swiper, SwiperSlide } from "swiper/react";
import dataSlider from "./promoData";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

function PromoCarousel() {
  const [newDataSlider, setNewDataSlider] = useState([]);
  const [hidePartnered, setHidePartnered] = useState(true);
  
  useEffect(() => {
    const companySettings = JSON.parse(
      localStorage.getItem("nlaPortalTokenExp")
    );
    if (companySettings) { // verify if key has been created on localStorage
      if (companySettings.companyLogo) {
        setHidePartnered(false); // show banner
      }else{
        setHidePartnered(true); // hide banner
      }
    }
    // eslint-disable-next-line
  }, [hidePartnered]);
  
  useEffect(() => {
    // new array showing all banners minus hideBanner=true
    setNewDataSlider(dataSlider(hidePartnered).filter(
      (slideItem) => !slideItem.hideBanner
    ));
    // eslint-disable-next-line
  },[hidePartnered]);

  
  return (
    <>
      <Styled.PromoBackground >
        <Styled.MaxWidth>
          <Styled.PromoWrapper>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {newDataSlider.map((slideItem, index) => {
                return (
                  <SwiperSlide key={index}>
                    { slideItem.isPartner && <SlidePartnered /> }
                    { slideItem.isTextImage && <SlideTextImage slideItem={slideItem} /> }
                    { slideItem.isFullImage && <SlideImage slideItem={slideItem} /> }
                    { slideItem.isTextImageNoPrice && <SlideTextImageNoPrice slideItem={slideItem} /> }
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Styled.PromoWrapper>
        </Styled.MaxWidth>
      </Styled.PromoBackground>
    </>
  );
}

export default PromoCarousel;
