import styled from 'styled-components';
import screenSize from '../../../styles/mediaQueries';
import Logo from '../../../images/horizontal-logo.png';

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;
 
  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`; 

export const MainWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoHeader = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-size: 125px 50px;
  width: 125px;
  height: 50px;
`;

export const Title = styled.h3`
  color: #6629D7;
  text-align: center;
  margin: 40px 0 20px 0;
`;

export const ContentText = styled.p`
  font-size: 1.7em;
  font-weight: 400;
  text-align: center;
  margin: 0 40px 20px 40px;

  ${screenSize.minLargeDesktop`
    font-size: 1.3em;
    margin: 0 0 20px 0;
  `}
`;

export const FormWrapper = styled.form`
  width: 360px;
  background-color: #6629D7;
  border-radius: 20px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  ${screenSize.minLargeDesktop`
    width: 400px;
  `}
`;

export const FormDescription = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 0;
`;

export const PrivacyDescription = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 3px 0 0 0;

  .highlight {
    font-weight: 600;
  }
  a {
    font-size: 14px;
    color: #FF874F;
    text-decoration: none;
    font-family: Specter, "Helvetica Neue", Helvetica, sans-serif;
  }
  a:hover {
    color: #EB6630;
    text-decoration: none;
  }
`;

export const SubscriptionDescription = styled.p`
  width: 340px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 30px 0 20px 0;
`;

export const CheckboxWrapper = styled.div`
  width: 340px;
  text-align: left;
  margin-bottom: 10px;
`;

export const AlertWrapper = styled.div`
  width: 90%;
  margin: 20px 0 0 0;

  ${screenSize.minLargeDesktop`
    width: 100%;
  `}
`;

export const CodeFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const ResendCodeWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: center;
`;