import React, { forwardRef } from "react";
import * as Styled from "./Top10LeasedEVCarousel.styled";
import { ButtonOrange } from "../../elements/Buttons";
import { handleCalendlyButtonClick } from '../../elements/Calendly/Calendly';
import { Vehicles } from "./Top10Data";
import Top10VehiclesSlide from "../Top10Vehicle";

const Top10LeasedEVCarousel = forwardRef(
  ({ isTablet, evDenied, ...props }, ref) => {
    return (
      <Styled.Top10LeasedWrapper
        style={{ display: evDenied ? "none" : "block" }}
      >
        <Styled.MaxWidth ref={ref} id="electric">
          <Styled.TitleWrapper>
            <Styled.Title>Top 10 leased electric vehicles</Styled.Title>
            <Styled.ContentText>
              Our expert consultants can also answer any questions, so you can
              sign up with confidence.
            </Styled.ContentText>
            <ButtonOrange onClick={handleCalendlyButtonClick} labelCTA="Book a consultation" /> 
          </Styled.TitleWrapper>
          <Top10VehiclesSlide Vehicles={Vehicles} isTablet={isTablet} />
        </Styled.MaxWidth>
      </Styled.Top10LeasedWrapper>
    );
  }
);

export default Top10LeasedEVCarousel;
