import React, { useState, useRef, useEffect } from "react";
import * as Styled from "./Login.styled";
import { ButtonOrange, ButtonPurpleTextLink } from "../../elements/Buttons";
import { InputText } from "../../elements/Inputs";
import { fetchEmail, fetchCode } from "../../../config/fetchAPI";
import Alert from "@mui/material/Alert";

function CodeVerify({ setIsCodeValid, email, codeDigits, setCodeDigits, signupAgreed }) {
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [codeError, setCodeError] = useState(false);
  const [resendCodeMsg, setResendCodeMsg] = useState(false);
  const [delayResendCode, setDelayResendCode] = useState(false);

  useEffect(() => {
    inputRefs[0].current.focus();
    // eslint-disable-next-line
  }, []);

  const handleCode = (e, index) => {
    let { value } = e.target;
    // Remove non-numeric characters
    value = value.replace(/\D/, "");
    if (value.length > 1) {
      // make sure input field accepts only one char
      return;
    }
    const newCodeDigits = [...codeDigits];
    newCodeDigits[index] = value;
  
    if (value === "") {
      // delete values from input field
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else {
      // add values on input field
      if (index < codeDigits.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  
    setCodeDigits(newCodeDigits);
    setCodeError(false); // hide error message
    setResendCodeMsg(false); // hide resend code msg
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handlePaste = async(e, startIndex) => {
    e.preventDefault();
    try {
      const clipboardData = await navigator.clipboard.readText();
      const pasteData = clipboardData.split(""); // array with copied data
      const newCodeDigits = [...codeDigits]; // inputs array
      
      let currentIndex = startIndex; // index of each input
      pasteData.forEach((digit) => {
        if (currentIndex < newCodeDigits.length) {
          newCodeDigits[currentIndex] = digit;
          currentIndex++;
        }
      });
      setCodeDigits(newCodeDigits);
    } catch (error) {
      console.error("Failed to read clipboard data:", error);
      return null;
    }
  }

  const handleResendCode = async () => {
    if(!delayResendCode){
      const result = await fetchEmail(email);
      if (result) {
        setResendCodeMsg(true); // show resend code msg
        setDelayResendCode(true); // button disabled for 30secs
        setCodeError(false); // hide error message
        setCodeDigits(["", "", "", "", "", ""]);
        inputRefs[0].current.focus();
      } 
    }
  };

  useEffect(() => {
    // debounce of 30secs for resend code
    if (delayResendCode) {
      const timer = setTimeout(() => {
        setDelayResendCode(false);
        setResendCodeMsg(false);
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [delayResendCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await fetchCode(email, codeDigits, signupAgreed); 
    const data = await result; 
    
    if (result) {
      // get company details and add to localStorage
      const company = data.message.company;
      const filters = data.message.filters;
      const dataCompanyLogo = company.logo;
      const dataCompanyId = company.id;
      const newCompanyName = company.name.replace("Pty Ltd", "");
      const dataCompanyName = newCompanyName;
      const dataEvDenied = filters.ev_denied;
      const dataIceDenied = filters.ice_denied; // true -> hide
      const dataOverLctDenied = filters.over_lct_denied; // true -> hide

      // save compny info into localstorega
      const companySettings = {
        expiration: new Date().getTime() + 30 * 24 * 60 * 60 * 1000, // Token 30 days
        companyLogo: dataCompanyLogo,
        companyId: dataCompanyId,
        companyName: dataCompanyName,
        evDenied: dataEvDenied,
        iceDenied: dataIceDenied,
        overLctDenied: dataOverLctDenied,
      };

      // Insert companySettings in localStorage
      localStorage.setItem(
        "nlaPortalTokenExp",
        JSON.stringify(companySettings)
      );

      // redirect to main page
      setIsCodeValid(true);
    } else {
      setCodeError(true); // show error message
    }
  };

  return (
    <Styled.MaxWidth>
      <Styled.MainWrapper>
        <Styled.InnerWrapper>
          <Styled.LogoHeader />
          <Styled.Title>Login</Styled.Title>
          <Styled.ContentText>Enter your code</Styled.ContentText>
          <Styled.FormWrapper onSubmit={handleSubmit}>
            <Styled.FormDescription>
              Enter the 6-digit code we sent to {email}
            </Styled.FormDescription>
            <Styled.CodeFieldsWrapper>
              {codeDigits.map((digit, index) => (
                <InputText
                  key={index}
                  ref={inputRefs[index]} 
                  label=""
                  value={digit}
                  error={codeError}
                  onChange={(e) => handleCode(e, index)}
                  onPaste={(e) => handlePaste(e, index)}
                  maxLength={1}
                  inputProps={{ 
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    style: { textAlign: 'center' } 
                  }}
                />
              ))}
            </Styled.CodeFieldsWrapper>
            <ButtonOrange
              type="submit"
              labelCTA="Login"
              style={{ width: "100%" }}
              onKeyDown={handleKeyPress}
            />
          </Styled.FormWrapper>
          <Styled.ResendCodeWrapper>
            <ButtonPurpleTextLink
              labelCTA="Resend Code"
              onClick={handleResendCode}
              disabled={delayResendCode}
              style={{ fontSize: "14px", fontWeight: "500" }}
            />
            {resendCodeMsg && <span>Code sent to {email}</span>}
          </Styled.ResendCodeWrapper>
          {codeError && (
            <Styled.AlertWrapper>
              <Alert severity="error">Code provided is invalid.</Alert>
            </Styled.AlertWrapper>
          )}
        </Styled.InnerWrapper>
      </Styled.MainWrapper>
    </Styled.MaxWidth>
  );
}

export default CodeVerify;
