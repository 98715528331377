import styled, { css } from "styled-components";
import screenSize from "../../../styles/mediaQueries";

export const ButtonWrapper = styled.div`
  display: block;

  ${screenSize.minDesktop`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `}
`;

export const ButtonPurple = styled.button`
  width: 100%;
  background-color: #6629d7;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #6e42f7;
    border-radius: 4px;
  }

  ${screenSize.minDesktop`
    width: auto;
  `}
`;

export const ButtonOrange = styled.button`
  width: 100%;
  background-color: #ff874f;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #eb6630;
    border-radius: 4px;
  }

  ${screenSize.minDesktop`
    width: auto;
  `}
`;

export const ButtonPurpleTextLink = styled.a`
  text-decoration: none;
  font-family: Specter, "Helvetica Neue", Helvetica, sans-serif;
  cursor: pointer;
  p {
    color: #6629d6;
    margin: 0;
  }
  p:hover {
    color: #6e42f7;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: default;
      p {
        color: gray;
      }
      p:hover {
        color: gray;
      }
    `}
`;

export const ButtonOrangeTextLink = styled.a`
  text-decoration: none;
  font-family: Specter, "Helvetica Neue", Helvetica, sans-serif;
  cursor: pointer;
  p {
    color: #FF874F;
    margin: 0;
  }
  p:hover {
    color: #EB6630;
  }
`;

export const TextWhite = styled.div`
  color: #ffffff;
  font-size: 1.4em;
  font-weight: 800;
  padding: 14px 24px;
  white-space: nowrap;
  font-family: Specter, Helvetica Neue, Helvetica, sans-serif;

  ${screenSize.minLargeDesktop`
    font-size: 1em;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
