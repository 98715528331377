import React from "react";
import * as Styled from "./Top10Vehicle.styled";
import { ButtonOrange, ButtonPurple } from "../../elements/Buttons";
import Truncate from '../../elements/Truncate/Truncate';
import { handleSendEmailButtonClick } from "../../elements/SendEmailLink/SendEmailLink";
import { PiEngine } from "react-icons/pi";
import { IoCarSportOutline } from "react-icons/io5";
import { BsSpeedometer2 } from "react-icons/bs";
import { GiGearStickPattern } from "react-icons/gi";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

import { Pagination, Navigation } from "swiper/modules";

function Top10Vehicle({ Vehicles, isTablet }) {

  return (
    <Styled.MaxWidth className="Top10Vehicles">
      <Styled.MainWrapper>
        <Swiper
          slidesPerView={isTablet ? "auto" : 4}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          navigation={isTablet ? false : true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {Vehicles.map((vehicle, indice) => (
            <SwiperSlide key={indice}>
              <Styled.VehicleCard $cardcolor={vehicle.cardColor}>
                <Styled.VehicleCardContent $cardcolor={vehicle.cardColor}>
                  <Styled.VehicleCardHeader>
                    <Styled.HeaderImg>
                      <Styled.VehicleImg
                        src={`https://d2fdjqmu21kuk8.cloudfront.net/${vehicle.nvicCode}.jpg`}
                        alt={vehicle.name}
                      />
                    </Styled.HeaderImg>
                  </Styled.VehicleCardHeader>

                  <Styled.VehicleTitle $cardcolor={vehicle.cardColor}>
                    {vehicle.name}
                  </Styled.VehicleTitle>

                  <Styled.VehicleContentWrapper>
                    <Styled.BlockWrapper>
                      <Styled.DetailsRow>
                        <PiEngine />
                        <Styled.BlockWrapper>
                          <Styled.DetailsRowLabel
                            $cardcolor={vehicle.cardColor}
                          >
                            Engine
                          </Styled.DetailsRowLabel>
                          <Styled.DetailsRowContent>
                            <Truncate text={vehicle.engineDesc} />
                          </Styled.DetailsRowContent>
                        </Styled.BlockWrapper>
                      </Styled.DetailsRow>
                      <Styled.DetailsRow>
                        <GiGearStickPattern />
                        <Styled.BlockWrapper>
                          <Styled.DetailsRowLabel
                            $cardcolor={vehicle.cardColor}
                          >
                            Transmission
                          </Styled.DetailsRowLabel>
                          <Styled.DetailsRowContent>
                            <Truncate text={vehicle.transmission} />
                          </Styled.DetailsRowContent>
                        </Styled.BlockWrapper>
                      </Styled.DetailsRow>
                    </Styled.BlockWrapper>

                    <Styled.BlockWrapper>
                      <Styled.DetailsRow>
                        <IoCarSportOutline />
                        <Styled.BlockWrapper>
                          <Styled.DetailsRowLabel
                            $cardcolor={vehicle.cardColor}
                          >
                            Body
                          </Styled.DetailsRowLabel>
                          <Styled.DetailsRowContent>
                            <Truncate text={vehicle.body} />
                          </Styled.DetailsRowContent>
                        </Styled.BlockWrapper>
                      </Styled.DetailsRow>
                      <Styled.DetailsRow>
                        <BsSpeedometer2 />
                        {vehicle.range !== "0" ? (
                          <Styled.BlockWrapper>
                            <Styled.DetailsRowLabel
                              $cardcolor={vehicle.cardColor}
                            >
                              Range
                            </Styled.DetailsRowLabel>
                            <Styled.DetailsRowContent>
                              {vehicle.range} <span className="rangeNote">(battery-only)</span>
                            </Styled.DetailsRowContent>
                          </Styled.BlockWrapper>
                        ) : (
                          <Styled.BlockWrapper>
                            <Styled.DetailsRowLabel
                              $cardcolor={vehicle.cardColor}
                            >
                              Consumption
                            </Styled.DetailsRowLabel>
                            <Styled.DetailsRowContent>
                              {vehicle.fuelConsumption}
                            </Styled.DetailsRowContent>
                          </Styled.BlockWrapper>
                        )}
                      </Styled.DetailsRow>
                    </Styled.BlockWrapper>
                  </Styled.VehicleContentWrapper>

                  <Styled.VehiclePriceWrapper>
                    <Styled.NotesStartFromTag $cardcolor={vehicle.cardColor}>
                      Starting <br />
                      from
                    </Styled.NotesStartFromTag>
                    <Styled.PriceTag $cardcolor={vehicle.cardColor}>
                      ${vehicle.weeklyPrice}
                    </Styled.PriceTag>
                    <Styled.NotesWeeklyTag $cardcolor={vehicle.cardColor}>
                      WEEKLY
                    </Styled.NotesWeeklyTag>
                  </Styled.VehiclePriceWrapper>
                  <Styled.CTAWrapper>
                    {vehicle.cardColor === "green" ? (
                      <ButtonPurple
                        onClick={handleSendEmailButtonClick}
                        labelCTA="Enquire now"
                      />
                    ) : (
                      <ButtonOrange
                        onClick={handleSendEmailButtonClick}
                        labelCTA="Enquire now"
                      />
                    )}
                  </Styled.CTAWrapper>
                </Styled.VehicleCardContent>
              </Styled.VehicleCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </Styled.MainWrapper>
    </Styled.MaxWidth>
  );
}

export default Top10Vehicle;
