import React from "react";
import * as Styled from "./VehicleComparison.styled";
import { Vehicles } from "./VehicleData";
import VehicleVersusIcon from "../../../images/bg/comparison-VS.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper/modules";

import "./styles.css";

function VehicleComparison({ isMobile, isTablet }) {
  let slidesPerView;
  let spaceBetween;

  if (isMobile) {
    slidesPerView = 1;
    spaceBetween = -60;
  } else if (isTablet) {
    slidesPerView = 2;
    spaceBetween = 10;
  } else {
    slidesPerView = 3;
    spaceBetween = 20;
  }

  return (
    <Styled.MaxWidth className="Comparison">
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {Vehicles.map((vehicle, indice) => {
          return (
            <SwiperSlide key={indice}>
              <Styled.VehiclesWrapper>
                {indice === 0 ? null : (
                  <Styled.VehicleVersusWrapper>
                    <img
                      className="VehicleVersusImg"
                      src={VehicleVersusIcon}
                      alt="versus"
                    />
                  </Styled.VehicleVersusWrapper>
                )}
                <Styled.VehicleImgTopWrapper>
                  <img
                    src={vehicle.carImgTop}
                    alt={vehicle.name}
                    className="VehicleImgTop"
                  />
                </Styled.VehicleImgTopWrapper>
                <Styled.VehicleCard $enginetype={vehicle.engineType}>
                  <Styled.VehicleCardHeaderWrapper
                    $enginetype={vehicle.engineType}
                  >
                    <img
                      src={vehicle.carBadge}
                      alt={vehicle.name}
                      className="HeaderImg"
                    />
                    <Styled.HeaderTitle>{vehicle.name}</Styled.HeaderTitle>
                  </Styled.VehicleCardHeaderWrapper>
                  <Styled.VehicleCardContent>
                    <Styled.VehicleContentInfo $enginetype={vehicle.engineType}>
                      <Styled.VehicleContentInfoEngineBadge
                        $enginetype={vehicle.engineType}
                      >
                        {vehicle.engineType}
                      </Styled.VehicleContentInfoEngineBadge>
                      <Styled.PriceWrapper>
                        <Styled.Price>{vehicle.rrpPrice}</Styled.Price>
                        <Styled.Drive>Drive Away</Styled.Drive>
                      </Styled.PriceWrapper>
                    </Styled.VehicleContentInfo>
                    <Styled.VehicleContentDetails>
                      <Styled.VehicleContentLabel>
                        <p>price (ex gst)</p>
                        <p>GST</p>
                        <p>stamp duty</p>
                        <p>registration & ctp</p>
                        <p>luxury car tax</p>
                      </Styled.VehicleContentLabel>
                      <Styled.VehicleContentDescription>
                        <p>{vehicle.priceExGST}</p>
                        <p>{vehicle.GST}</p>
                        <p>{vehicle.stampDuty}</p>
                        <p>{vehicle.registrationCtp}</p>
                        <p>{vehicle.LuxuryCarTax}</p>
                      </Styled.VehicleContentDescription>
                    </Styled.VehicleContentDetails>
                    <Styled.VehicleContentInfo $enginetype={vehicle.engineType}>
                      <Styled.PurchasePriceLabel>
                        GST SAVINGS ON PURCHASE PRICE:
                      </Styled.PurchasePriceLabel>
                      <Styled.PriceWrapper>
                        <Styled.Price>{vehicle.gstSavingsPrice}</Styled.Price>
                      </Styled.PriceWrapper>
                    </Styled.VehicleContentInfo>
                  </Styled.VehicleCardContent>
                  <Styled.VehicleCardFooter $enginetype={vehicle.engineType}>
                    <Styled.VehicleCardFooterTitle>
                      How much will it cost me?
                    </Styled.VehicleCardFooterTitle>
                    <Styled.VehicleCardFooterPriceWrapper>
                      <Styled.BlockWrapper>
                        <Styled.PeriodBadge>Weekly</Styled.PeriodBadge>
                        <Styled.Price>{vehicle.weeklyCost}<span className="disclaimer">*</span></Styled.Price>
                      </Styled.BlockWrapper>
                      <Styled.BlockWrapper>
                        <Styled.PeriodBadge>Annually</Styled.PeriodBadge>
                        <Styled.Price>{vehicle.annuallyCost}<span className="disclaimer">*</span></Styled.Price>
                      </Styled.BlockWrapper>
                    </Styled.VehicleCardFooterPriceWrapper>
                  </Styled.VehicleCardFooter>
                </Styled.VehicleCard>
              </Styled.VehiclesWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Styled.MaxWidth>
  );
}

export default VehicleComparison;
