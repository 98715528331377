import React from "react";
import * as Styled from "./FAQ.styled";
import * as MUIStyled from "./MUIStyled";
import { Questions } from "./FAQData";
import { FaPlus, FaMinus } from "react-icons/fa6";

export function AccordionContent() {
  const [expanded, setExpanded] = React.useState(-1);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Styled.MaxWidth>
      {Questions.map((item, indice) => {
        return (
          <Styled.AccordionWrapper key={indice}>
            <MUIStyled.CustomAccordion
              expanded={expanded === indice}
              onChange={handleChange(indice)}
            >
              <MUIStyled.CustomAccordionSummary
                aria-controls={`content-${indice}`}
                id={`header-${indice}`}
              >
                {item.question}
                {expanded === indice ? <FaMinus /> : <FaPlus />}
              </MUIStyled.CustomAccordionSummary>
              <MUIStyled.CustomAccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
              </MUIStyled.CustomAccordionDetails>
            </MUIStyled.CustomAccordion>
          </Styled.AccordionWrapper>
        );
      })}
    </Styled.MaxWidth>
  );
}

export default AccordionContent;
