import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries";
import RibbonBg from "../../../images/bg/ribbon-orange-vert-left.png";
import CaseStudiesImage from "../../../images/bg/case-studies.png";

export const BlockWrapper = styled.div``; 

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const Background = styled.div`
  min-height: 400px;
  background-color: #6629D7;
  padding: 0;

  ${screenSize.minDesktop`
    min-height: 400px;
    background-color: #6629D7;
    padding: 40px 0;

    background-image: url(${RibbonBg});
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  `}
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  

  ${screenSize.minDesktop`
    display: grid;
    grid-gap: 40px;
    align-items: center;
    grid-template-columns: 30% 70%; 
  `}
`;

export const CaseStudiesLeftImg = styled.div`
  background-image: url(${CaseStudiesImage});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  height: 200px;

  ${screenSize.minDesktop`
    background-image: url(${CaseStudiesImage});
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    height: 360px;
  `}
`;

export const ContentWrapper = styled.div`
  width: 90%;
  margin-top: 30px;
`;

export const ContentTitle = styled.h1`
  color: #ffffff;
  font-size: 2.7em;
  padding-left: 45px;
  margin: 0;

  ${screenSize.minDesktop`
    padding-left: 0;
    margin: 0;
  `}
`;

export const ContentTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0 30px 45px;

  ${screenSize.minDesktop`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    padding: 30px 0;
  `}
`;

export const ContentText = styled.div`
  max-width: 100%;

  ${screenSize.minDesktop`
    max-width: 45%;
  `}
`;

export const ContentTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; 
`;

export const ContentTextTitle = styled.h6`
  color: #ffffff;
  font-size: 1.6em;
  margin: 0;

  ${screenSize.minLargeDesktop`
    max-width: 320px; 
    color: #ffffff;
    font-size: 1.2em;
    margin: 0;
  `}
`;

export const ContentTextNumberWrapper = styled.div`
  display: none;

  ${screenSize.minDesktop`
    display: block;
    height: 40px;
    width: 40px;
  `}
`;

export const ContentTextNumber = styled.div`
  height: 40px;
  width: 40px;
  color: #ffffff;
  font-size: 1.5em;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border: 2px solid #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  span{
    margin-bottom: -8px;
  }
`;

export const ContentTextBody = styled.p`
  color: #ffffff;
  font-size: 1.4em;
  margin-bottom: 40px;

  ${screenSize.minLargeDesktop`
    font-size: 1.2em;
    margin-bottom: 0;
  `}
`;
