import React, { useEffect, useState } from "react";
import * as Styled from "./ScrollToTop.styled";
import { FaArrowUp } from "react-icons/fa6";

const ScrollToTop = () => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 800 && !!hidden) setHidden(false);
      else if (window.scrollY <= 800 && !hidden) setHidden(true);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [hidden]);

  const handleClick = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    
      <Styled.ScrollToTop hidden={hidden} onClick={handleClick}>
        <FaArrowUp />
      </Styled.ScrollToTop>
    
  );
};

export default ScrollToTop;
