export const Questions = [
  {
    question: 'Is there a minimum salary to get a novated lease?',
    answer: `No, there is no minimum salary to get a novated lease.<br /><br />
    Novated leasing approval is dependent on your capacity as a borrower much like traditional finance. 
    This means you need to illustrate your ability to meet regular repayments over the term of the lease.<br /><br /> 
    Chat with one of our experts about how much you may be able to borrow. 
    `,
  },
  {
    question: 'How long will my novated lease be?',
    answer: `The term of the lease agreement is completely up to you.<br /><br />
    Generally, terms of one to five years are available under a novated lease.<br /><br />
    The length of the lease term will determine your repayments and your residual. `,
  },
  {
    question: 'What is the minimum and maximum I can get for a car?',
    answer: `The amount you can borrow for a vehicle will vary depending on the lender.<br /><br />
    In general, the minimum novated lease amount is $10,000, while higher amounts (up to $500k) are subject entirely to the repayment capacity of the applicant.<br /><br />
    Chat with one of our experts about how much you may be able to borrow. `,
  },
  {
    question: 'Can you help me get a novated lease anywhere in Australia?',
    answer: `Yes, our team members are located all throughout Australia.<br /><br />
    Our head office is in Sydney and we have further locations in Melbourne, Perth, Brisbane and Adelaide.`,
  },
  {
    question: 'Can I buy a used car with a novated lease?',
    answer: `Yes, you can buy a used car both privately or through a dealership.<br /><br />
    Some financiers will allow the vehicle to be up to 15 years of age at least end.<br /><br />
    Buying a used car from a dealership will also still see you save the GST on the purchase price.`,
  },
  {
    question: 'What happens at the end of the novated lease?',
    answer: `You have a few options available at the end of your novated lease term:<br /><br />
    <strong>Upgrade</strong> - most of our customers tend to upgrade their vehicle, your expert consultant can help you find the right upgrade for you and even help you sell your current vehicle to help pay out the residual. If the sale price is higher than the residual, you keep that profit - tax-free!<br /><br />
    <strong>Extend</strong> -  If you want to keep the same car and continue enjoying the tax savings, we can help you refinance the residual amount for another term of your choice.<br /><br />
    <strong>Own outright</strong> - Pay the residual amount (including GST) at the end of the lease term to own the car outright`,
  },
  {
    question: 'What happens if I leave my current job during a novated lease?',
    answer: `If you leave your job during the term of your novated lease, you will still be responsible for making the finance repayments on the vehicle.<br /><br />
    The lease will be de-novated, where the running costs are removed from the agreement and repayments work much the same way as a standard car loan.<br /><br />
    If you are going to a new employer who offers novated leasing as an employee, you may be able to transfer your novated lease over under a re-novation, or request Novated Lease Australia set up as a partner.
    `,
  },
];