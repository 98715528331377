import React from "react";
import * as Styled from "./WhyNovatedLeaseAustralia.styled";
import * as MUIStyled from "./MUIStyled";
import { ContentItems } from "./ContentItemsData";

export function AccordionContentMobile() {
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Styled.MaxWidth>
      {ContentItems.map((item, indice) => {
        return (
          <Styled.BlockWrapper key={indice}>
            <MUIStyled.CustomAccordion
              expanded={expanded === indice}
              onChange={handleChange(indice)}
            >
              <MUIStyled.CustomAccordionSummary
                aria-controls={`content-${indice}`}
                id={`header-${indice}`}
              >
                {item.selectorText}
              </MUIStyled.CustomAccordionSummary>
              <MUIStyled.CustomAccordionDetails>
                <Styled.BlockWrapper>
                  <Styled.ContentInnerTitle>
                    {item.title}
                  </Styled.ContentInnerTitle>
                  <Styled.ContentInnerCopy>
                    <span dangerouslySetInnerHTML={{ __html: item.content }} />
                  </Styled.ContentInnerCopy>
                </Styled.BlockWrapper>
              </MUIStyled.CustomAccordionDetails>
            </MUIStyled.CustomAccordion>
          </Styled.BlockWrapper>
        );
      })}
    </Styled.MaxWidth>
  );
}

export default AccordionContentMobile;
