import React, { forwardRef } from "react";
import * as Styled from "./CaseStudies.styled";
import CaseStudiesContent from "./CaseStudiesContent";

const CaseStudies = forwardRef(({ isTablet, ...props }, ref) => {
  
  return (
    <Styled.Background>
      <Styled.MaxWidth ref={ref} id="caseStudies">
        <Styled.MainWrapper>
          <Styled.CaseStudiesLeftImg />
          <Styled.ContentWrapper>
            <Styled.ContentTitle>Case study</Styled.ContentTitle>
            <CaseStudiesContent isTablet={isTablet} />
          </Styled.ContentWrapper>
        </Styled.MainWrapper>
      </Styled.MaxWidth>
    </Styled.Background>
  );
});

export default CaseStudies;
