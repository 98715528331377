import styled from "styled-components";
import screenSize from "../../../styles/mediaQueries"; 
import BenefitsImg from "../../../images/bg/novatedleasebenefits.png";

export const BlockWrapper = styled.div``;

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;

  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`;

export const MainWrapper = styled.div` 
  margin: 40px 0 80px 0;

  ${screenSize.minDesktop`
    margin: 90px 0 120px 0;
  `}
`;

export const Title = styled.h1` 
  color: #6629D7;
  text-align: center;
  margin: 0;
  padding: 0 40px;

  ${screenSize.minDesktop`
    padding: 0;
  `}
`;

export const ButtonWrapper = styled.div` 
    display: block;
    margin: 40px 40px;
  
  ${screenSize.minDesktop`
    display: flex;
    justify-content: center;
    margin-top: 40px;
  `}
`;

export const CardTitle = styled.h6`
  height: 55px;
  color: #ffffff;
  font-size: 24px;
  margin: 0;
`;

export const CardContent = styled.p`
  color: #ffffff;
  font-size: 18px;
  padding: 10px 0;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    color: #ffffff;
    font-size: 1.8em;
  }

  ${screenSize.minLargeDesktop`
    svg {
      color: #ffffff;
      font-size: 1.5em;
    }
  `}
`;

export const BenefitsBg = styled.div`
  background-image: url(${BenefitsImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  width: auto;
  height: 200px;

  ${screenSize.minDesktop`
    background-image: url(${BenefitsImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    width: 300px;
    height: auto;

    @media only screen and (max-width: 1300px) {
      width: 250px;
    }
  `}

`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1160px;
  margin: 60px 0;

  ${screenSize.minDesktop`
    flex-direction: row;
    width: 100%;
    height: auto;
  `}
`;

export const Card = styled.div`
  flex: 1;
  height: 340px;
  position: relative;
  overflow: hidden;
`;

export const TopContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #000;
  padding: 40px 40px;
  transition: transform 0.5s ease;
  transform: ${props => props.$show ? 'translateX(-100%)' : 'translateX(0)'};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled(TopContent)`
  color: #ffffff;
  background-color: #4B1F9C;
  padding: 40px 40px;
  cursor: pointer;

  &.lightPurpleBackground {
    background-color: #6629D7;
  }
`;