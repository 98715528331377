import React from 'react';
import * as Styled from "./Footer.styled";
import { ButtonPurpleTextLink } from '../../elements/Buttons';
import { FiPhone } from "react-icons/fi";

function Footer() {
  return (
    <Styled.Background>
      <Styled.MainWrapper>
        <Styled.LinksWrapper>
          <Styled.LinksInnerWrapper>
            <Styled.LogoHeader />
            {/* <ButtonPurpleTextLink labelCTA="Lease calculator" style={{ fontSize: '14px', fontWeight: '500'}} /> */}
          </Styled.LinksInnerWrapper>
          <Styled.BlockWrapper>
            <Styled.PhoneWrapper>
              <FiPhone />
              <ButtonPurpleTextLink labelCTA="1300 920 773" href="tel:1300920773" style={{ fontSize: '16px'}} />
            </Styled.PhoneWrapper>
          </Styled.BlockWrapper>
        </Styled.LinksWrapper>
        <Styled.CopyrightWrapper>
          <Styled.CopyrightText>© 2023 by Novated Lease Australia</Styled.CopyrightText>
          <Styled.CopyrightInnerWrapper>
            <ButtonPurpleTextLink labelCTA="Privacy Policy" href="https://www.novatedleaseaustralia.com.au/privacy-policy" target="_blank" style={{ fontSize: '12px', fontWeight: '500'}} />
            <ButtonPurpleTextLink labelCTA="Terms of Service" href="https://www.novatedleaseaustralia.com.au/terms-of-use" target="_blank" style={{ fontSize: '12px', fontWeight: '500'}} />
          </Styled.CopyrightInnerWrapper>
        </Styled.CopyrightWrapper>
      </Styled.MainWrapper>
    </Styled.Background>
  );
}

export default Footer;