import React from "react";
import * as Styled from "./CaseStudies.styled";

const Contents = [
  {
    bulletPoint: "1",
    title: "Could you tell us a little about yourself?",
    copyText: `My name is Jack, I currently work as a Logistics & Distributions Manager for a company called Clifton Productions.
    We plan, ship, and build some of the biggest music festivals around Australia. My day to day includes prepping and shipping jobs all around Australia, as well as jumping on the tools to help the crews on occasions.`,
  },
  {
    bulletPoint: "2",
    title: "What lead you to Novated Lease Australia?",
    copyText: `After having some car troubles with my previous car, it was time that I moved on from the old rig and upgraded! My boss had recently sorted his new car through Novated Lease Australia, so he referred me.`,
  },
  {
    bulletPoint: "3",
    title:
      "What benefits were most important to you that novated leasing was able to provide?",
    copyText: `The benefits that were most important to me were no longer having to constantly put money aside for every bill related to having a car, as everything is covered under a simple and easy budgeted repayment. Also, being able to bring my taxable income down was great. The monetary benefits from obtaining a novated lease are outstanding!`,
  },
  {
    bulletPoint: "4",
    title: "Would you refer others to Novated Lease Australia?",
    copyText: `I most definitely have and will continue to refer others to Novated Lease Australia. The overall experience made buying a car that much smoother and easier - taking one less problem away from day to day life.`,
  },
];

function CaseStudiesContent({ isTablet }) {
  return (
    <Styled.BlockWrapper>
      <Styled.ContentTextWrapper>
        {Contents.map((content, indice) => (
          <Styled.ContentText key={indice}>
            <Styled.ContentTitleWrapper>
              <Styled.ContentTextNumberWrapper>
                <Styled.ContentTextNumber>
                  <span>{content.bulletPoint}</span>
                </Styled.ContentTextNumber>
              </Styled.ContentTextNumberWrapper>
              <Styled.ContentTextTitle>{!isTablet ? '' : content.bulletPoint + '. ' } {content.title}</Styled.ContentTextTitle>
            </Styled.ContentTitleWrapper>
            <Styled.ContentTextBody>{content.copyText}</Styled.ContentTextBody>
          </Styled.ContentText>
        ))}
      </Styled.ContentTextWrapper>
    </Styled.BlockWrapper>
  );
}

export default CaseStudiesContent;
