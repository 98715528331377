// Check if the environment is staging or production
const customerMsBaseUrl = process.env.REACT_APP_CUSTOMER_MS_BASE_URL;

const endpoints = {
  getCode: '/api/v1/auth/get-code', // API - verify company email address
  submitCode: '/api/v1/auth/submit-code', // API - verify code
  submitSignup: '/api/v1/signup', // API - Signup Iterable
};

function getFullUrl(endpoint) {
  return `${customerMsBaseUrl}${endpoints[endpoint]}`;
}

export const getCodeUrl = getFullUrl('getCode');
export const submitCodeUrl = getFullUrl('submitCode');
export const submitSignupUrl = getFullUrl('submitSignup');

// API access Key
export const X_NLA_API_KEY = "3cidnGkeH566YKpps8TRHZmZiOZQNp2sPcHMgCmrJvPlZkZ6qpKnATiLi0aXk1s9";
