import styled from 'styled-components';
import screenSize from '../../../styles/mediaQueries';
import Logo from '../../../images/horizontal-logo.png';

export const MaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1430px;
 
  ${screenSize.minDesktop`
    max-width: 1590px;
  `}
`; 

export const MainWrapper = styled.div`
  padding: 20px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;

  ${screenSize.minDesktop`
    padding: 20px;
    border-bottom: none;
  `}
`;

export const LogoHeader = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-size: 100px 35px;
  width: 100px;
  height: 35px;

  ${screenSize.minDesktop`
    background-size: 168px 63px;
    width: 168px;
    height: 63px;
  `}
`;

export const PartnerLogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PartnerLogoText = styled.p`
  display: none;
  font-size: 1.1em;
  padding-left: 20px;

  ${screenSize.minDesktop`
    display: block;
  `}
`;

export const PartnerLogoImg = styled.img`
  width: auto;
  height: 28px;
  margin-left: 15px;

  ${screenSize.minDesktop`
    width: auto;
    height: 40px;
    margin-left: 30px;
  `}
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
`;

export const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #6629d6;
    font-size: 1.2em;
    margin-right: 6px;
  }
`;

export const PhoneNumber = styled.a`
  color: #6629D6;
  font-size: 1.2em;
  text-decoration: none;
  white-space: nowrap;
  font-family: Specter, "Helvetica Neue", Helvetica, sans-serif;

  svg{
    font-size: 1em;
  }
`;

export const PhoneNumberMobile = styled.a`
  color: #6629D6;
  font-size: 1.1em;
  text-decoration: none;
`;

export const MobileRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;

  svg {
    color: #6629d6;
    font-size: 2.2em;
    margin-right: 6px;
    cursor: pointer;
  }

  ${screenSize.minDesktop`
    column-gap: 20px;
  `}
`;