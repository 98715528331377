import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Truncate = ({ text }) => {
  if (text.length > 20) {
    return (
      <LightTooltip title={text} placement="right">
        <span>{text.substring(0, 20)}...</span>
      </LightTooltip>
    );
  }
  return <span>{text}</span>;
};

export default Truncate;
