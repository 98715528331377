import React, { forwardRef } from "react";
import * as Styled from "./FAQ.styled";
//import Vimeo from "@u-wave/react-vimeo";
import { ButtonOrange } from "../../elements/Buttons";
import { handleCalendlyButtonClick } from "../../elements/Calendly/Calendly";
import AccordionContent from "./AccordionContent";

const FAQSection = forwardRef((props, ref) => {
  return (
    <Styled.MaxWidth ref={ref} id="faq">
      <Styled.MainWrapper>
        <Styled.BlockWrapper>
          <Styled.Title>Have novated lease questions?</Styled.Title>
          <Styled.Content>
            Learn more about how a novated lease works from start to finish. Our
            expert consultants can also answer any questions, so you can sign up
            with confidence.
          </Styled.Content>
          <ButtonOrange onClick={handleCalendlyButtonClick} labelCTA="Book a consultation" />
        </Styled.BlockWrapper>
        <AccordionContent />
      </Styled.MainWrapper>
    </Styled.MaxWidth>
  );
});

export default FAQSection;
