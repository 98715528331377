import React from 'react';

function TrustpilotWidget({
  dataTemplateId,
  dataBusinessUnitId,
  dataStyleHeight,
  dataStyleWidth,
  dataTheme,
  dataStars,
  dataFontFamily,
  children,
}) {
  return (
    <div
      className="trustpilot-widget"
      data-locale="en"
      data-template-id={dataTemplateId}
      data-businessunit-id={dataBusinessUnitId}
      data-style-height={dataStyleHeight}
      data-style-width={dataStyleWidth}
      data-theme={dataTheme}
      data-stars={dataStars}
      data-review-languages="en"
      data-font-family={dataFontFamily}>
      {children}
    </div>
  );
}

export default TrustpilotWidget;
