export const Vehicles = [
  {
    nvicCode: "0EDP24",
    name: "TESLA MODEL Y",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "5D WAGON",
    fuelConsumption: "0",
    range: "533km",
    weeklyPrice: "226",
    cardColor: "green",
  },
  {
    nvicCode: "0EDN24",
    name: "TESLA MODEL 3",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D SEDAN",
    fuelConsumption: "0",
    range: "629km",
    weeklyPrice: "216",
    cardColor: "green",
  },
  {
    nvicCode: "0COZ24",
    name: "BYD ATTO 3",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D WAGON",
    fuelConsumption: "0",
    range: "420km",
    weeklyPrice: "180",
    cardColor: "green",
  },
  {
    nvicCode: "0DOT24",
    name: "MG MG4",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "5D HATCHBACK",
    fuelConsumption: "0",
    range: "350 to 530km",
    weeklyPrice: "146",
    cardColor: "green",
  },
  {
    nvicCode: "0E2923",
    name: "BYD SEAL",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D SEDAN",
    fuelConsumption: "0",
    range: "460 to 570km",
    weeklyPrice: "179",
    cardColor: "green",
  },
  {
    nvicCode: "0DXK24",
    name: "BMW iX3",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D WAGON",
    fuelConsumption: "0",
    range: "460km",
    weeklyPrice: "284",
    cardColor: "green",
  },
  {
    nvicCode: "0DP024",
    name: "VOLVO XC40",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D WAGON",
    fuelConsumption: "0",
    range: "450 to 510km",
    weeklyPrice: "249",
    cardColor: "green",
  },
  {
    nvicCode: "0E7G24",
    name: "KIA EV6",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D WAGON",
    fuelConsumption: "0",
    range: "528km",
    weeklyPrice: "244",
    cardColor: "green",
  },
  {
    nvicCode: "0DOQ24",
    name: "BYD DOLPHIN",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "5D HATCHBACK",
    fuelConsumption: "0",
    range: "340 to 427km",
    weeklyPrice: "148",
    cardColor: "green",
  },
  {
    nvicCode: "0E6L24",
    name: "KIA EV9",
    engineDesc: "ELECTRIC",
    transmission: "1 SP AUTOMATIC",
    body: "4D WAGON",
    fuelConsumption: "0",
    range: "443 to 512km",
    weeklyPrice: "276",
    cardColor: "green",
  },
];
