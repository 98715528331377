import React from "react";
import * as Styled from "./PromoCarousel.styled";
import { ButtonOrange } from "../../elements/Buttons";
import { handleCalendlyButtonClick } from '../../elements/Calendly/Calendly';

function SlideTextImage({slideItem}) {
  return (
    <Styled.ContentWrapper>
      <Styled.PromoContent>
        <Styled.TitleBadgeWrapper>
          <Styled.TitleBadge>{slideItem.titleBadge}</Styled.TitleBadge>
        </Styled.TitleBadgeWrapper>
        <Styled.PromoContentTitle color={'#ffffff'}>
          {slideItem.vehicleTitle}
        </Styled.PromoContentTitle>
        <Styled.PromoContentSubTitle>
          {slideItem.vehicleSubTitle}
        </Styled.PromoContentSubTitle>
        <Styled.PromoContentPrice>
          <span className="from">from</span>
          <sup className="small">$</sup>
          {slideItem.priceDollar}
          <span className="period">{slideItem.period}</span>
        </Styled.PromoContentPrice>
        <ButtonOrange labelCTA="Get quote" onClick={handleCalendlyButtonClick} />
          <Styled.PromoContentDisclaimerText>
            {slideItem.disclaimerText}
          </Styled.PromoContentDisclaimerText>
      </Styled.PromoContent>
      <picture>
        <source
          media="(max-width: 799px)"
          srcSet={`${slideItem.imageMobile}`}
        />
        <source media="(min-width: 800px)" srcSet={`${slideItem.image}`} />
        <Styled.VehicleImg src={slideItem.image} alt={slideItem.vehicleTitle} />
      </picture>
    </Styled.ContentWrapper>
  );
}

export default SlideTextImage;
