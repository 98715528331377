import React, { useState, useEffect, useRef } from "react";
import * as Styled from "./Signup.styled";
import { InputText } from "../../elements/Inputs";
import { BsSendFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { buttonSx } from "./loadButtonStyled";
import { fetchSignup } from "../../../config/fetchAPI";
import { emailValid } from "../../../config/validation";

function Signup({ companyId }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const timer = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    // clear email field after success
    if (success) {
      timer.current = setTimeout(() => {
        setSuccess(false);
        setEmail("");
      }, 10000);
    }
  }, [success]);

  const handleSignup = (e) => {
    setEmail(e.target.value);
  };

  const handleClearError = () => {
    setSuccess(false);
    setError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await fetchSignup(email, companyId);

    if (emailValid(email)) {
      if (result) {
        if (!loading) {
          setSuccess(false);
          setLoading(true);
          setError(false);
          timer.current = setTimeout(() => {
            setSuccess(true);
            setLoading(false);
          }, 2000);
        }
      } else {
        console.log("error on fetching");
      }
    } else {
      setError(true);
    }
    // Remove focus from the input field
    document.activeElement.blur();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Styled.Background>
      <Styled.MaxWidth>
        <Styled.SignupWrapper>
          <Styled.SignupTitle>
            Where car specials meet relevant news
          </Styled.SignupTitle>
          <Styled.NotificationCard>
            {success ? (
              <Styled.CardCheckWrapper>
                <Styled.CheckIcon>
                  <FaCheck />
                </Styled.CheckIcon>
                <Styled.CardCopy>Thank you for subscribing!</Styled.CardCopy>
              </Styled.CardCheckWrapper>
            ) : (
              <Styled.CardWrapper>
                <Styled.EmailIcon>
                  <BsSendFill />
                </Styled.EmailIcon>
                <Styled.CardCopy>
                  Easy to digest, exclusive specials, timely content. What's not
                  to love.
                  <br />
                  <strong>Join us today.</strong> <i>Leave when you like.</i>
                </Styled.CardCopy>
              </Styled.CardWrapper>
            )}
          </Styled.NotificationCard>

          <Styled.SignupFormWrapper onSubmit={handleSubmit}>
            <InputText
              error={error}
              id={error}
              helperText={error ? "Invalid email address" : ""}
              label="email address"
              value={email}
              onChange={handleSignup}
              onFocus={handleClearError}
              style={{ width: "100%" }}
            />
            <Styled.SignupFormButtonWrapper>
              <Button
                type="submit"
                variant="contained"
                sx={buttonSx(success)}
                disabled={loading}
                onKeyDown={handleKeyPress}
              >
                {success ? "Sent!" : "Try it"}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#6629D7",
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
              )}
            </Styled.SignupFormButtonWrapper>
          </Styled.SignupFormWrapper>
        </Styled.SignupWrapper>
      </Styled.MaxWidth>
    </Styled.Background>
  );
}

export default Signup;
