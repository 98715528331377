import React, { useState } from "react";
import * as Styled from "./Login.styled";
import { InputText, CheckboxLabel } from "../../elements/Inputs";
import { ButtonOrangeIconRight } from "../../elements/Buttons";
import { fetchEmail } from "../../../config/fetchAPI";
import Alert from "@mui/material/Alert";
import { FaArrowRightLong } from "react-icons/fa6";

function Login({ setIsEmailValid, email, setEmail, setSignupAgreed, signupAgreed }) {
  const [emailError, setEmailError] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(false); // hide error message
  };

  const handleCheckbox = () => {
    // if signup -> true: save email on the API.
    setSignupAgreed(!signupAgreed);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await fetchEmail(email);
    if (result) {
      setIsEmailValid(true); // call code page
    } else {
      setEmailError(true); // show error message
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Styled.MaxWidth>
      <Styled.MainWrapper>
        <Styled.InnerWrapper>
          <Styled.LogoHeader />
          <Styled.Title>Login</Styled.Title>
          <Styled.ContentText>
            Login using the email address that you use at work.
            {emailError && (
              <Styled.AlertWrapper>
                <Alert severity="error">Invalid email address.</Alert>
              </Styled.AlertWrapper>
            )}
          </Styled.ContentText>
          <Styled.FormWrapper onSubmit={handleSubmit}>
            <InputText
              label="email address"
              onChange={handleEmail}
              value={email}
              style={{ width: "100%" }}
            />
            <Styled.PrivacyDescription>
              By clicking <span className="highlight">Agree and continue</span>,
              I agree to Novated Lease Australia's
              <a
                href="https://www.novatedleaseaustralia.com.au/privacy-policy"
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                Privacy Policy
              </a>
              .
            </Styled.PrivacyDescription>
            <ButtonOrangeIconRight
              type="submit"
              labelCTA="Agree and continue"
              icon={<FaArrowRightLong />}
              style={{ width: "100%", paddingTop: "4px" }}
              onKeyDown={handleKeyPress}
            />
          </Styled.FormWrapper>
          <Styled.SubscriptionDescription>
            Novated Lease Australia will send you employee-only emails
            containing special car offers and relevant industry news. You can
            opt out of receiving these at any time, directly from the email.
          </Styled.SubscriptionDescription>
          <Styled.CheckboxWrapper>
            <CheckboxLabel
              onChange={handleCheckbox}
              label="I don't want to receive marketing messages from Novated Lease Australia"
            />
          </Styled.CheckboxWrapper>
        </Styled.InnerWrapper>
      </Styled.MainWrapper>
    </Styled.MaxWidth>
  );
}

export default Login;
